import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const webapi = environment.URL;
@Injectable({
  providedIn: 'root'
})
export class StationService {

  constructor(private http: HttpClient) { }


  AddStations(obj){
        debugger;
        return this.http.post<any>(webapi + 'addStations.php/createStation',obj);
    }

  ViewStations(obj){
        debugger;
        return this.http.post<any>(webapi + 'addStations.php/getStations',obj);
    }
     EditStations(obj){
        debugger;
        return this.http.post<any>(webapi + 'addStations.php/editStations',obj);
    }
     DeleteStations(obj){
        debugger;
        return this.http.post<any>(webapi + 'deleteBranches.php/deleteBranch',obj);
    }
     GetStationCodes(){
        debugger;
        return this.http.post<any>(webapi + 'tracking.php/getTruckAirport',{});
    }

}
