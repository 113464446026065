import { Component, OnInit } from "@angular/core";
import { StationService } from "../../../services/station/station.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
    FormGroupDirective,
    AbstractControl
} from "@angular/forms";
@Component({
    selector: "app-editstations",
    templateUrl: "./editstations.component.html",
    styleUrls: ["./editstations.component.css"]
})
export class EditstationsComponent implements OnInit {
    Logindetails: any;
    editStationForm: FormGroup;
    viewData: any;
    handlerId: any;
    handlerName: any;
    constructor(
        private readonly route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private stationService: StationService,
        private spinner: NgxSpinnerService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        debugger;
        this.viewData = JSON.parse(localStorage.getItem("viewData"));
        this.Logindetails = JSON.parse(localStorage.getItem("loginresultobject"));

        this.editStationForm = this.formBuilder.group({
            contactName: new FormControl(this.viewData.handlerName),
            stationCode: new FormControl(this.viewData.stationCode, [Validators.required]),
            emailId: new FormControl(this.viewData.emailId, [Validators.required, Validators.email]),
            address: new FormControl(this.viewData.address, [Validators.required]),
            latitude: new FormControl(this.viewData.lattitude),
            longitude: new FormControl(this.viewData.longitude),
        });
    }


    editStation() {
        debugger;
        const obj = {
            "handlerId": this.viewData.handlerId,
            "handlerName": this.editStationForm.value.contactName,
            "stationCode": this.editStationForm.value.stationCode,
            "emailId": this.editStationForm.value.emailId,
            "address": this.editStationForm.value.address,
            'latitude': this.editStationForm.value.latitude,
            "longitude": this.editStationForm.value.longitude
        }
        console.log(this.editStationForm);
        this.spinner.show();
        this.stationService.EditStations(obj).subscribe(
            data => {
                debugger;
                console.log(data);
                this.spinner.hide();
                if (data.status == "1") {
                    this.toastr.success("Successfully updated", "", { timeOut: 1500 });
                    this.router.navigateByUrl("/viewstations");
                } else {
                    this.toastr.error(data.message, "", {});
                }
            },
            error => {
                this.spinner.hide();
            }
        );
    }
    gotoback() {
        debugger;
        this.router.navigateByUrl("/viewstations");
    }
}
