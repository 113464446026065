import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const webAPI = environment.URL;


@Injectable({
    providedIn: 'root'
})
export class MyAccountService {

    constructor(private http: HttpClient) { }

    myAccountDetails(userId) {
        debugger;
        console.log(userId)
        return this.http.post<any>(webAPI + "myAccount.php/viewUserDetails", { userId })
    }

    forgotPassword(emailid) {
        debugger;
        return this.http.post<any>(webAPI + 'myAccountCclpPortal.php/forgotPasswordCclpPortal', { emailid })
    }

    passwordChange(obj) {
        debugger;
        return this.http.post<any>(webAPI + 'checkListSave.php/changePassword', {
            "userId": obj.userId,
            "oldPassword": obj.oldPassword,
            "newPassword": obj.newPassword
        })
    }

    updateUserDetails(obj) {
        debugger;
        return this.http.post<any>(webAPI + 'myAccountCclpPortal.php/editProfile', obj)
    }

getOrgStations(obj){
    debugger;
    return this.http.post<{obj: {orgId : string}}>(webAPI +'myAccountCclpPortal.php/getStationsCclpPortal',obj)
}

    // UpdateEmail(obj){
    //    debugger;
    //    return this.http.post<any>(webAPI + 'myAccountCclpPortal.php/editProfile', obj )
    // }
}
// http://testtruckingaeroapi.trucking.aero/