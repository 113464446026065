import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { SuperadminComponent } from './superadmin/superadmin.component';
import { UsersComponent } from './users/users.component';
import { CSAAdminComponent } from './csa-admin/csa-admin.component';
import { MyaccountComponent } from './shared/myaccount/myaccount.component';
import { SettingsComponent } from './shared/settings/settings.component';
import { CreateuserComponent } from './csa-admin/createuser/createuser.component';
import { ViewReprotsComponent } from './shared/view-reprots/view-reprots.component';
import { ViewUsersComponent } from './shared/view-users/view-users.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ViewdetailsComponent } from './shared/viewdetails/viewdetails.component';
import { AddstationsComponent } from './shared/stations/addstations/addstations.component';
import { EditstationsComponent } from './shared/stations/editstations/editstations.component';
import { ViewstationsComponent } from './shared/stations/viewstations/viewstations.component';
import { AuthenticateGuard } from './AuthGuard/authenticate.guard';
import { CreateorgComponent } from './superadmin/createorg/createorg.component';
import { EditorgComponent } from './superadmin/editorg/editorg.component';
import { AddAirlineComponent } from './csa-admin/add-airline/add-airline.component';
import { CsaAirlineDashboardComponent } from './csa-admin/csa-airline-dashboard/csa-airline-dashboard.component';
import { ViewAirlineEmailsComponent } from './csa-admin/view-airline-emails/view-airline-emails.component';
import { EdituserComponent } from './csa-admin/edituser/edituser.component';
import { UnauthroizedUsersComponent } from './shared/unauthroized-users/unauthroized-users.component';
const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'admin-dashboard', component: AdminComponent, canActivate: [AuthenticateGuard] },
  { path: 'sa-dashboard', component: SuperadminComponent, canActivate: [AuthenticateGuard] },
  { path: 'csa-dashboard', component: CSAAdminComponent, canActivate: [AuthenticateGuard] },
  { path: 'user-dashboard', component: UsersComponent, canActivate: [AuthenticateGuard] },
  { path: 'myaccount', component: MyaccountComponent, canActivate: [AuthenticateGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthenticateGuard] },
  { path: 'createuser', component: CreateuserComponent, canActivate: [AuthenticateGuard] },
  { path: 'edituser', component: EdituserComponent, canActivate: [AuthenticateGuard] },
  { path: 'unauth-users', component: UnauthroizedUsersComponent, canActivate: [AuthenticateGuard] },

  { path: 'viewusers', component: ViewUsersComponent, canActivate: [AuthenticateGuard] },
  { path: "viewusers/:orgKey/:type", component: ViewUsersComponent, canActivate: [AuthenticateGuard] },
   { path: "viewusers/:type", component: ViewUsersComponent, canActivate: [AuthenticateGuard] },
  { path: "viewusers/:orgLogo", component: ViewUsersComponent, canActivate: [AuthenticateGuard] },
  { path: 'viewreports', component: ViewReprotsComponent, canActivate: [AuthenticateGuard] },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'viewdetails', component: ViewdetailsComponent, canActivate: [AuthenticateGuard] },
  { path: "viewdetails/:orgKey", component: ViewdetailsComponent, canActivate: [AuthenticateGuard] },
  { path: "viewdetails/:orgKey/:type", component: ViewdetailsComponent, canActivate: [AuthenticateGuard] },
  { path: 'addstations', component: AddstationsComponent, canActivate: [AuthenticateGuard] },
  { path: 'viewstations', component: ViewstationsComponent, canActivate: [AuthenticateGuard] },
  { path: 'editstations', component: EditstationsComponent, canActivate: [AuthenticateGuard] },
  { path: 'createorg', component: CreateorgComponent, canActivate: [AuthenticateGuard] },
  { path: 'editorg', component: EditorgComponent, canActivate: [AuthenticateGuard] },
  { path: 'addairline_email', component: AddAirlineComponent, canActivate: [AuthenticateGuard] },
  { path: 'csa-airline-dashboard', component: CsaAirlineDashboardComponent, canActivate: [AuthenticateGuard] },
//   { path: 'csa-airline-dashboard/:orgKey', component: CsaAirlineDashboardComponent, canActivate: [AuthenticateGuard] },
{ path: 'view-airline-emails', component: ViewAirlineEmailsComponent, canActivate: [AuthenticateGuard] },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
