import { Component, OnInit ,enableProdMode, ViewChild} from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}
@Component({
  selector: 'app-unauthroized-users',
  templateUrl: './unauthroized-users.component.html',
  styleUrls: ['./unauthroized-users.component.css']
})
export class UnauthroizedUsersComponent implements OnInit {

  widgetInputData = [
    {    checkbox:'',
         firstName:'pavan',
         LastName:'Kumar',
         EmailId:'pavan@gmail.com',
         Externalreport: '',
         CreatedOn:'2019-12-16',
         HandlerCompanyName:'Aviapartner Amsterdam',
         StationCode:'AMS',
       
   },
  { checkbox:'',
    firstName:'Raghu',
    LastName:'kumar',
    EmailId:'Raghukumar@gmail.com',
    Externalreport: '',
    CreatedOn:'2019-12-16',
    HandlerCompanyName:'Dnata Amsterdam',
    StationCode:'Vij',
   },
    { checkbox:'',
      firstName:'kumar',
      LastName:'balaji',
      EmailId:'balaji@gmail.com',
      Externalreport: '',
      CreatedOn:'2019-12-14',
      HandlerCompanyName:'cargohub',
      StationCode:'RJY',
      },
      { checkbox:'',
        firstName:'Kirnkumar',
        LastName:'j',
        EmailId:'Kirnkumar@gmail.com',
        Externalreport: '',
        CreatedOn:'2019-05-12',
        HandlerCompanyName:'menzies world cargo',
        StationCode:'Hyd',
       },
        { checkbox:'',
          firstName:'billaaheed',
          LastName:'j',
          EmailId:'billaaheed@gmail.com',
          Externalreport: '',
          CreatedOn:'2019-12-10',
          HandlerCompanyName:'Portground GmbH',
          StationCode:'BOM',
         },]  
  constructor() { }
  @ViewChild('grid', { static: false }) dataGrid: DxDataGridComponent;
  dataGrid1: DxDataGridComponent;
  ngOnInit() {
 }
}
