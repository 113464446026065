import { Component, OnInit, enableProdMode } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
    Always;
    getSelectedData;
  emailid:any;
Password:any;
usertype:any;
  Organizations: any;
  Object:any;
userName:any;
organizationDetails:any;
recentActivites:any;
  constructor(private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService, private superAdminservice: SuperadminService, private spinner: NgxSpinnerService) { }
  dataGrid1: DxDataGridComponent;
  ngOnInit() {
    debugger;
    //  this.userName = JSON.parse(localStorage.getItem('loginresultobject'));
    //  this.organizationDetails =this.userName.organizationDetails;
    //    this.recentActivites =this.userName.recentActivites;


    this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
    this.emailid=this.Object.emailId;
    this.Password=this.Object.password;
     this.usertype=this.Object.userType;
         this.spinner.show();
     this.superAdminservice.getUserDashBoard(this.emailid,this.usertype).subscribe(
            data => {
                console.log(data)
                this.spinner.hide();
                if (data.responseCode == '1') {
                    //this.toastr.success('Login Successfully', '', {});
                    localStorage.setItem('userDashBoardData', JSON.stringify(data));
                        localStorage.setItem('DashBoardData', JSON.stringify(data));
                     this.organizationDetails =data.organizationDetails;
                    this.recentActivites =data.recentActivites;
                }
                else {
                    this.toastr.error(data.message, '', {});
                }
            },
            error => {
                this.spinner.hide();
            }
        )
  }

}
