import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

// import { ViewUsersComponent }from '../view-users/view-users.component'
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
@Component({
    selector: 'app-viewdetails',
    templateUrl: './viewdetails.component.html',
    styleUrls: ['./viewdetails.component.css'],
    //   providers: [ViewReports]
})
export class ViewdetailsComponent implements OnInit {
    eachReport: any;
    Images: any;
    damageImage: any;
    webapi: string;
    isfrom: string;
    currentUrl: any;
    previousUrl: any;
    subscribedParam: any;
    comapanydetails: any;
    LoginDetails: any;
    constructor(private router: Router, private ViewReportsData: ViewReportsData, private readonly route: ActivatedRoute) {

    }

    ngOnInit() {
        debugger;
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.route.paramMap.subscribe(
            params => {
                this.subscribedParam = params.get("orgKey");
                // this.CompanyLogoimgurl = params.get("orgLogo")
            });
        this.route.paramMap.subscribe(
            params => {
                this.subscribedParam = params.get("type");
                // this.CompanyLogoimgurl = params.get("orgLogo")
            });
        this.webapi = environment.URL;
        // this.damageImage = [];
        this.eachReport = JSON.parse(localStorage.getItem('eachReport'));
        this.Images = this.eachReport.damageImage;
        this.damageImage = this.Images.split(",");
    }

    navigateToReports() {
        debugger;
        //   this.isfrom = "viewDetails"
        if (this.ViewReportsData.isfromHandlerCsaDashboard === 'yes') {
            debugger;
            this.router.navigate(["/csa-dashboard"]);
            this.ViewReportsData.isfromHandlerCsaDashboard = 'no'
        } else if (this.ViewReportsData.isfromairlineCsaDashboard === 'yes') {
            this.router.navigate(["/csa-airline-dashboard"]);
            this.ViewReportsData.isfromairlineCsaDashboard = 'no'
        } else if (this.ViewReportsData.isfromViewReports === 'yes') {
            this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
            if (this.LoginDetails.userType === 'SA') {
                this.router.navigate(["/viewusers", this.comapanydetails.orgId]);
                this.ViewReportsData.isfromViewReports = 'no'
                this.ViewReportsData.isFrmViewDetails = "yes"
            } else {
                this.router.navigate(["/viewusers", this.comapanydetails.orgId, this.subscribedParam]);
                this.ViewReportsData.isfromViewReports = 'no'
                this.ViewReportsData.isFrmViewDetails = "yes"
            }
        }else{

        }
    }
}