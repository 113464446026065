import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { environment } from 'src/environments/environment';



const webapi = environment.URL;
// new HttpHeaders({'X_VERIFY'  : 'c5094bf910d3f505149a4d592feb56b746b32e9cf78193c02d92c9cee7d14bb4'})

let headers =  new HttpHeaders({
    'X_VERIFY'  : 'c5094bf910d3f505149a4d592feb56b746b32e9cf78193c02d92c9cee7d14bb4'
});



@Injectable({
    providedIn: 'root'
})
export class SuperadminService {

    constructor(private http: HttpClient) { }

    viewUsers(OrgId) {
        debugger;
        return this.http.get<any>(webapi + 'getLoginCclpPortal.php/companyUsersInfo?organisationId=' + OrgId);
    }

    viewUserandReports(OrgId) {
        debugger;
        return this.http.get<any>(webapi + 'getLoginCclpPortal.php/getUserReportInfo?organisationId=' + OrgId);

    }
    viewActivation(activateObj) {
        debugger;
        return this.http.post<any>(webapi + 'myAccountCclpPortal.php/setCompanyActiveCclpPortal', activateObj);
    }


    getSuperAdminDashBoard(emailid, usertype) {
        debugger;
        return this.http.get<any>(webapi + 'getLoginCclpPortal.php/getSuperAdminDashboard?emailId=' + emailid + '&userType=' + usertype);

    }
    getCompanySuperAdminDashBoard(emailid, usertype,orgType) {
        debugger;
        return this.http.get<any>(webapi + 'getLoginCclpPortal.php/getCompanyAdminDashboard?emailId=' + emailid + '&userType=' + usertype + '&orgType=' + orgType);

    }
    getUserDashBoard(emailid, usertype) {
        debugger;
        return this.http.get<any>(webapi + 'getLoginCclpPortal.php/getUserDashboard?emailId=' + emailid + '&userType=' + usertype);

    }

    authenticate(obj){
        debugger;
        return this.http.post<any>(webapi + 'myAccountCclpPortal.php/setUserAuthCclpPortal', obj);
    }

    airlineData(){
        debugger;
        return this.http.post<any>(webapi + 'tracking.php/getTruckAirlineDetails',{})
    }
    createOrg(obj){
        debugger;
        console.log(obj)
        return this.http.post<any>(webapi + 'companyDetailsCclpPortal.php/createCompanyCclpPortal',obj,{headers: headers})
    }

    deleteOrg(obj){
    debugger;
    return this.http.post<any>(webapi + 'deleteOrganisation.php/deleteOrganisation', obj)
    }

    OrgAuth(obj){
        debugger;
        return this.http.post<any>(webapi + 'myAccountCclpPortal.php/setAuthActiveCclpPortal',obj)
    }
}
