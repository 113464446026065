import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective, } from '@angular/forms';
import { organizationModal } from './Organization.modal'
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { SuperadminService } from '../../services/superadmin/superadmin.service'
import { StationService } from '../../services/station/station.service';
import { ToastrService } from 'ngx-toastr';

import { finalize } from 'rxjs/operators';
@Component({
    selector: 'app-createorg',
    templateUrl: './createorg.component.html',
    styleUrls: ['./createorg.component.css']
})
export class CreateorgComponent implements OnInit {
    // CreateOrgForm: FormGroup;
    createOrgForm: FormGroup;
    airlineNames = [];
    airlineData: any;
    airlineorg: any;
    filteredStates: Observable<any[]>;
    states: any = [];
    companyName: FormControl;
    stationsListObj: any;
    stationsList: Observable<any[]>;
    stationCode: FormControl;
    showAutocomplete: boolean;
    createFormStatus: string;
    responsedata: any;
    responsedatatemp: any;

    constructor(private formBuilder: FormBuilder, private SuperadminService: SuperadminService, private StationService: StationService, private toastr: ToastrService, private spinner: NgxSpinnerService, ) {
        this.companyName = new FormControl('', [Validators.required]);
        this.stationCode = new FormControl('', [Validators.required]);

        this.filteredStates = this.companyName.valueChanges
            .pipe(
                startWith(''),
                map(state => state ? this.filterStates(state) : this.states.slice())
            );


        this.stationsList = this.stationCode.valueChanges
            .pipe(
                startWith(''),
                map(stateobj => stateobj ? this.filterStations(stateobj) : this.states.slice())
            );
    }
    // createOrg = new organizationModal('','','','','','',null,'','');
    ngOnInit() {
        debugger;
        this.createOrgForm = this.formBuilder.group({
            companyName: new FormControl(this.companyName, [Validators.required]),
            orgType: new FormControl(null, [Validators.required]),
            firstName: new FormControl(null, [Validators.required]),
            lastName: new FormControl(null, [Validators.required]),
            emailId: new FormControl(null, [Validators.required, Validators.email]),
            contactNumber: new FormControl(null, [Validators.required, Validators.pattern('[1-9]\\d{9}')]),
            address: new FormControl(null, [Validators.required]),
            stationCode: new FormControl(this.stationCode, [Validators.required]),
            carrierId: new FormControl(null)
        });
        this.createOrgForm.get('orgType').valueChanges.subscribe(value => {
            debugger;
            console.log(value);
            console.log(this.createOrgForm);
            // alert(value)
            if (value === 'A') {
                this.spinner.show();
                this.SuperadminService.airlineData().subscribe(
                    data => {
                        debugger;
                        // this.airlineData = data;
                        this.states = data.airline_details;
                        console.log(this.states)
                        console.log(data)
                        this.spinner.hide();

                    }
                )
            }
        });
        this.StationService.GetStationCodes().subscribe(
            data => {
                this.stationsListObj = data.airport_details
                debugger;
                // console.log(data)
            },
            error => {
                this.spinner.hide();
            }
        )
        console.log(this.stationsListObj)

        this.createOrgForm.controls['companyName'].valueChanges.subscribe(value => {
            debugger;
            console.log(value);
        });

        // "companyName":"CastusInfo12247 Ltd",
        // "orgType":"A",
        // "firstName":"safdd",
        // "lastName":"Aviation",
        // "emailId":"annadisudharshanreddy@gmail.com",
        // "contactNumber":8989898989,
        // "address":"Hyderabad",
        // "stationCode":"AMS",
        // "carrierId":206
    }

    // organization Name
    fn_createOrgForm(event) {
        debugger;
        console.log(this.createOrgForm);
        // let createorgform =  event.currentTarget
        this.SuperadminService.createOrg(this.createOrgForm.value).pipe(finalize(() => {
            debugger;
            this.responsedatatemp = this.responsedata;

        })).subscribe(data => {
            debugger;
            console.log(data);
            console.log(event);
            this.responsedata = data
            if (data.responseCode === 1) {
                console.log(event)
                this.responsedata = data;
                this.toastr.success(data.message);
                //  this.createOrgForm.reset()
                // this.createFormStatus = 'success'

            } else {
                this.toastr.error(data.message)
            }
        });
        if (this.responsedata.responseCode === 1) {
            debugger;
            event.currentTarget.reset()
            this.createOrgForm.reset()
        } else {
            return null
        }

    }
    filterStates(carrierName: string) {
        debugger;
        if (this.createOrgForm.value.orgType === 'A') {
            return this.states.filter(state =>
                state.carrierName.toLowerCase().indexOf(carrierName.toLowerCase()) === 0);
        } else {
            this.createOrgForm.controls['companyName'].setValue(carrierName);
            // this.createOrgForm.controls['companyName'].setErrors(null);
        }
    }
    onEnter(state) {
        debugger;
        console.log(state)
        this.createOrgForm.controls['companyName'].setValue(state.carrierName);
        this.createOrgForm.controls['companyName'].setErrors(null);
        this.createOrgForm.controls['carrierId'].setValue(state.carrierId);
        console.log(this.createOrgForm)
    }


    filterStations(code: string) {
        debugger;
        // this.stationsListObj = JSON.parse(localStorage.getItem('stationsList'));
        console.log(this.stationsListObj)
        return this.stationsListObj.filter(state =>
            state.code.toLowerCase().indexOf(code.toLowerCase()) === 0);
    }

    onSelect(state) {
        debugger;
        console.log(state)
        this.createOrgForm.controls['stationCode'].setValue(state.code);
        this.createOrgForm.controls['stationCode'].setErrors(null);
        console.log(this.createOrgForm)
    }

    updatedVal(e) {
        debugger;
        if (e && e.length === 0) {
            this.showAutocomplete = true;
        } else {
            this.showAutocomplete = false;
        }
    }

}
