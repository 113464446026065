import { Component, OnInit, enableProdMode } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}
@Component({
  selector: 'app-view-reprots',
  templateUrl: './view-reprots.component.html',
  styleUrls: ['./view-reprots.component.css']
})
export class ViewReprotsComponent implements OnInit {

  constructor() { }
  dataGrid1: DxDataGridComponent;
  ngOnInit() {
  }

}
