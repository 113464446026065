import { Component, OnInit, enableProdMode, ViewChild } from '@angular/core';
import { ViewReportsData } from '../../data/DataShareViewDetails';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from 'src/environments/environment';
if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}

// import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
@Component({
    selector: 'app-csa-dashboard',
    templateUrl: './csa-dashboard.component.html',
    styleUrls: ['./csa-dashboard.component.css']
})
export class CsaDashboardComponent implements OnInit {
    emailid: any;
    Password: any;
    usertype: any;
    Organizations: any;
    Object: any;
    Obj: any;
    orgType: any;
    AuthUserReport = [];
    UnAuthUserReport = [];
    AuthrisedUsers = [];
    UNAuthrisedUsers = [];
    Always;
    finalReportArray = [];
    mergedReports = [];

    internalStatus = [];
    externalStatus = [];
    subscribedParam: any;
    showUser: boolean;
    Status: any;
    OrgId: any;
    usersList = []; loginData: any;
    orgIdValue: any;
    reportsList: any;
    showReports: boolean;
    webapi: string;
    comapanydetails: any;
    CompanyLogoimgurl: string;
    CompanyName: string;
    ObjectOne: any;
    constructor(private ViewReportsData: ViewReportsData, private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService, private superAdminservice: SuperadminService, private spinner: NgxSpinnerService) { }

    ngOnInit() {
        debugger;
        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        this.emailid = this.Object.emailId;
        this.Password = this.Object.password;
        this.usertype = this.Object.userType;
        this.orgType = this.Object.orgType;
        this.webapi = environment.URL;
        this.spinner.show();
        this.superAdminservice.getCompanySuperAdminDashBoard(this.emailid, this.usertype, this.orgType).subscribe(
            data => {
                debugger;
                if (data.responseCode == '1') {
                    // this.toastr.success('Login Successfully', '', {});
                    localStorage.setItem('saDashBoardData', JSON.stringify(data));
                    localStorage.setItem('DashBoardData', JSON.stringify(data));
                    this.Obj = data;
                    this.Organizations = data.organizationDetails;

                    console.log(data)
                    this.spinner.hide();
                    this.usersList = data.userDetails;
                    this.reportsList = data.userReports;
                    console.log(this.usersList);
                    console.log(this.reportsList);
                    this.finalReportArray = [];
                    this.mergedReports = [];

                    if (data.orgType === 'H') {
                        for (let i = 0; i < this.usersList.length; i++) {
                            debugger;
                            for (let j = 0; j < this.reportsList.length; j++) {
                                if (this.usersList[i].userId == this.reportsList[j].userId) {
                                    this.reportsList[j].firstName = this.usersList[i].firstName;
                                    this.reportsList[j].jobTitle = this.usersList[i].jobTitle;
                                    this.finalReportArray.push(this.reportsList[j]);
                                }
                            }
                        }
                    } else {
                        this.finalReportArray = this.reportsList;
                    }

                    for (let i = 0; i < this.usersList.length; i++) {
                        if (this.usersList[i].userStatus === 'A') {
                            this.usersList[i].userStatus = 'Active';
                        } else if (this.usersList[i].userStatus === 'I') {
                            this.usersList[i].userStatus = 'Inactive';
                        }
                    }

                    for (let index = 0; index < this.finalReportArray.length; index++) {
                        if (this.finalReportArray[index].emailStatus === 'N' && this.finalReportArray[index].schedulingTime != null) {
                            this.finalReportArray[index].emailStatus = 'finalTimer';
                        } else if (this.finalReportArray[index].emailStatus === 'S') {
                            this.finalReportArray[index].emailStatus = 'Sent';
                        }
                        else if (this.finalReportArray[index].emailStatus === 'ST') {
                            this.finalReportArray[index].emailStatus = 'finalTimer';
                        } else if (this.finalReportArray[index].emailStatus === 'H') {
                            this.finalReportArray[index].emailStatus = 'Hold';
                        }
                        else if (this.finalReportArray[index].emailStatus === 'C') {
                            this.finalReportArray[index].emailStatus = 'Cancelled';
                        } else if (this.finalReportArray[index].emailStatus === 'N' && this.finalReportArray[index].schedulingTime === null) {
                            this.finalReportArray[index].emailStatus = 'authorisation failed';
                        }

                    }
                    console.log('Array', this.finalReportArray);

                    for (var i = 0; i < this.finalReportArray.length; i++) {
                        var status = this.containsObject(this.finalReportArray[i], this.mergedReports);
                        if (status) {
                            var idx = this.mergedReports.findIndex(c => c.masterAwbNo == this.finalReportArray[i].masterAwbNo);
                            this.mergedReports[idx].type = this.finalReportArray[i].reportType;
                            this.mergedReports[idx].extrenalpath = this.finalReportArray[i].reportPath;
                            this.mergedReports[idx].emailStatus = this.finalReportArray[i].emailStatus;

                        } else {
                            this.mergedReports.push(this.finalReportArray[i]);

                        }
                    };
                    debugger;
                    for (var i = 0; i < this.mergedReports.length; i++) {
                        if (this.mergedReports[i].extrenalpath) {
                            let epath = this.mergedReports[i].extrenalpath;
                            let externalPath = epath.split("/");
                            this.mergedReports[i].externalFileName = externalPath[1];
                        }
                        if (this.mergedReports[i].reportPath) {
                            let ipath = this.mergedReports[i].reportPath;
                            let internalPath = ipath.split("/");
                            this.mergedReports[i].internalFileName = internalPath[1];
                        }
                    }

                    if (this.subscribedParam == 'All') {
                        this.mergedReports = this.mergedReports;
                    } else if (this.subscribedParam == 'AuthrisedUsers') {
                        for (let i = 0; i < this.usersList.length; i++) {
                            for (let j = 0; j < this.mergedReports.length; j++) {
                                if (this.usersList[i].userId == this.mergedReports[j].userId) {
                                    if (this.usersList[i].adminAuth == 'A') {
                                        this.AuthUserReport.push(this.mergedReports[j]);
                                    }
                                }

                            }
                        }
                        this.mergedReports = this.AuthUserReport;
                    } else if (this.subscribedParam == 'UnAuthrisedUsers') {
                        for (let i = 0; i < this.usersList.length; i++) {
                            for (let j = 0; j < this.mergedReports.length; j++) {
                                if (this.usersList[i].userId == this.mergedReports[j].userId) {
                                    if (this.usersList[i].adminAuth == 'I') {
                                        this.UnAuthUserReport.push(this.mergedReports[j]);
                                    }
                                }
                            }
                        }
                        this.mergedReports = this.UnAuthUserReport;
                    }
                    if (data.responseCode == '1') {
                    }
                    else {
                        this.toastr.error(data.message, '', { timeOut: 1500 });
                        this.spinner.hide();
                    }

                    console.log("before", this.mergedReports)
                    console.log("after", this.mergedReports)
                    console.log(this.mergedReports);
                }
                else {
                    this.toastr.error(data.message, '', {});
                }
            },
            error => {
                this.spinner.hide();
            }
        )


    }
    containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].masterAwbNo === obj.masterAwbNo) {
                return true;
            }
        }
        return false;
    }
    getUser(obj, type) {
        debugger;
        console.log(obj.organisationId);
        let comapanydetails = {
            orgId: obj.organisationId,
            orgLogo: obj.orgLogo,
            orgName: obj.organisationName
        }
        //localStorage.setItem('Usertype', JSON.stringify(type));
        localStorage.setItem('comapanydetails', JSON.stringify(comapanydetails));
        this.router.navigate(["viewusers", obj.organisationId, type]);
        if (type === 'report') {
            this.ViewReportsData.isFrmViewDetails = "yes"
        }
    }
      getSelectedData() {
        debugger;
        if (this.dataGrid.instance.getSelectedRowsData().length != 0) {
            console.log("fgasdgahgdhdagshdhgashghdg", this.dataGrid.instance.getSelectedRowsData()[0]);
            localStorage.setItem("eachReport", JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0]));
            if(this.subscribedParam === undefined){
                 this.router.navigate(["/viewdetails", this.Object.orgId]);
                 this.ViewReportsData.isfromHandlerCsaDashboard = 'yes'
            }else{
                 this.router.navigate(["/viewdetails", this.Object.orgId, this.subscribedParam]);
                 this.ViewReportsData.isfromHandlerCsaDashboard = 'yes'
            }


        }
    }
    @ViewChild('grid', { static: false }) dataGrid: DxDataGridComponent;
    dataGrid1: DxDataGridComponent;


}
