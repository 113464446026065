import { Component, OnInit } from '@angular/core';
import { MyAccountService } from '../services/myAccount/my-account.service'
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
    emailId: string;
    passwordForm: FormGroup;
    constructor(private MyAccountService: MyAccountService, private toastr: ToastrService,private spinner: NgxSpinnerService) { }

    ngOnInit() {
        this.passwordForm = new FormGroup({
            'emailId': new FormControl(null, [Validators.required, Validators.email])
        })
    }


    sendEmail(passwordForm, formDirective: FormGroupDirective) {
        debugger;
        this.spinner.show();
        this.MyAccountService.forgotPassword(passwordForm.value.emailId).subscribe(
            data => {
                debugger;
                console.log(data)
                this.spinner.hide();
                if(data.resposeCode === 1){
                this.toastr.success(data.message)
                }else{
                    this.toastr.error(data.message)
                }
                formDirective.resetForm();
                this.passwordForm.reset()
            },
            error => {
                // alert ('Unable to verify email id ')
            }
        )
    }

}
