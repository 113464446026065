import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-airline-emails',
  templateUrl: './view-airline-emails.component.html',
  styleUrls: ['./view-airline-emails.component.css']
})
export class ViewAirlineEmailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
