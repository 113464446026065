import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment'

const webapi = environment.URL;

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }
  login(LoginForm) {
    return this.http.get<any>( webapi + 'getLoginCclpPortal.php/getLogin?emailId=' + LoginForm.LoginForm.value.emailId + '&password=' + LoginForm.LoginForm.value.password)

}

}
