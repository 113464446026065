import { Component, OnInit, enableProdMode, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
declare var jQuery: any;
import { ViewReportsData } from 'src/app/data/DataShareViewDetails';
import { ShareLoginDataService } from '../../data/share-login-data.service'

if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}
@Component({
    selector: 'app-view-users',
    templateUrl: './view-users.component.html',
    styleUrls: ['./view-users.component.css']
})
export class ViewUsersComponent implements OnInit {
    dataSourceReportsArray = [];
    AuthUserReport = [];
    UnAuthUserReport = [];
    AuthrisedUsers = [];
    UNAuthrisedUsers = [];
    @ViewChild('grid', { static: false }) dataGrid: DxDataGridComponent;
    dataGrid1: DxDataGridComponent;
    Always;
    finalReportArray = [];
    mergedReports = [];

    internalStatus = [];
    externalStatus = [];
    subscribedParam: any;
    showUser: boolean;
    Status: any;
    OrgId: any;
    usersList = []; loginData: any;
    orgIdValue: any;
    reportsList: any;
    showReports: boolean;
    webapi: string;
    comapanydetails: any;
    CompanyLogoimgurl: string;
    CompanyName: string;
    Object: any;
    loginDetails:any
    constructor(private ViewReportsData: ViewReportsData, private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService, private superAdminservice: SuperadminService, private spinner: NgxSpinnerService) { }

    ngOnInit() {
        debugger;
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.loginDetails = JSON.parse(localStorage.getItem('loginresultobject'));

        this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
        this.webapi = environment.URL;
        debugger;

        if(this.comapanydetails === null){
            this.CompanyLogoimgurl = '';
        this.CompanyName = this.Object.organisationName
        }else{
             this.CompanyLogoimgurl = this.comapanydetails.orgLogo
        this.CompanyName = this.comapanydetails.orgName
        }

        this.route.paramMap.subscribe(
            params => {
                this.subscribedParam = params.get("orgKey");
                // this.CompanyLogoimgurl = params.get("orgLogo")
            });
        this.route.paramMap.subscribe(
            params => {
                this.subscribedParam = params.get("type");
                // this.CompanyLogoimgurl = params.get("orgLogo")
            });
        // console.log(this.subscribedParam);
        // console.log('orgId' + this.comapanydetails.orgId);
        // console.log('orgLogo' + this.comapanydetails.orgLogo);
        // console.log(this.CompanyLogoimgurl);
        if(this.ViewReportsData.isfromSideBarViewReports === 'yes'){
             document.getElementById('reports').className = 'nav-link active';
            document.getElementById('users').className = 'nav-link';
            this.showUser = false;
            this.showReports = true;
            this.Service();
        }else

        if (this.ViewReportsData.isFrmViewDetails === "yes") {
            debugger;
            document.getElementById('reports').className = 'nav-link active';
            document.getElementById('users').className = 'nav-link';
            this.showUser = false;
            this.showReports = true;
             this.ViewReportsData.isFrmViewDetails = "no"
            this.Service();

        } else {
            this.showUser = true;
             this.ViewReportsData.isFrmViewDetails = "no"
            if (this.comapanydetails.isfrom === 'airlines') {
                this.getairlinedata()
            } else {
                this.getUsers();

            }


        }
    }
    containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].masterAwbNo === obj.masterAwbNo) {
                return true;
            }
        }
        return false;
    }
    gotoReports() {
        this.showUser = false;
        this.showReports = true;
        this.ViewReportsData.isFrmViewDetails ='yes';
        this.Service();
        this.spinner.hide();
    }
    getSelectedData() {
        debugger;
        if (this.dataGrid.instance.getSelectedRowsData().length != 0) {
            console.log("fgasdgahgdhdagshdhgashghdg", this.dataGrid.instance.getSelectedRowsData()[0]);
            localStorage.setItem("eachReport", JSON.stringify(this.dataGrid.instance.getSelectedRowsData()[0]));
            this.router.navigate(["/viewdetails", this.comapanydetails.orgId, this.subscribedParam]);
            this.ViewReportsData.isfromViewReports = 'yes'

        }
    }
    authentication(data) {
        debugger;
        jQuery('#myModal').modal({ backdrop: 'static', keyboard: false });

    }

    authenticate(data) {
        debugger;
        let Object = JSON.parse(localStorage.getItem('loginresultobject'));
        console.log(this.subscribedParam);
        console.log(data.data.userId)
        let dataObject = {
            userId: data.data.userId,
            // orgId: this.subscribedParam
            orgId: this.comapanydetails.orgId
        }
        this.spinner.show();
        this.superAdminservice.authenticate(dataObject).subscribe(data => {
            this.spinner.hide();
            if (data.responseCode === 1) {
                this.toastr.success(data.message, '', {});
                jQuery('#myModal').modal('hide');
                this.ngOnInit();
            } else {
                this.toastr.error(data.message, '', {});
                jQuery('#myModal').modal('hide');
            }
        },
            error => {
                this.spinner.hide();
                this.toastr.error(data.message, '', {});
            })
    }
    getUsers() {
        this.showUser = true;
        this.showReports = false;
         this.ViewReportsData.isFrmViewDetails = "no"
        debugger;
        this.spinner.show();
        this.superAdminservice.viewUsers(this.comapanydetails.orgId).subscribe(
            data => {
                debugger;
                this.spinner.hide();
                this.usersList = [];
                this.AuthrisedUsers = [];
                this.UNAuthrisedUsers = [];
                this.usersList = data.userDetails;
                // this.usersList1 = data.userDetails;

                for(let i=0; i<this.usersList.length; i++){
                  if(this.usersList[i].adminAuth === 'I' && this.usersList[i].superAdminAuth === 'I'){
                this.usersList[i].finalAuth = 'I';
                  }else{
                this.usersList[i].finalAuth = 'A';
                  }
                }

                if (this.subscribedParam == 'All') {
                    this.usersList = data.userDetails;
                } else if (this.subscribedParam == 'AuthrisedUsers') {
                    console.log(this.usersList[0].adminAuth);
                    for (let index = 0; index < this.usersList.length; index++) {
                        console.log(this.usersList[index].adminAuth);
                        if (this.usersList[index].adminAuth == 'A') {
                            this.AuthrisedUsers.push(this.usersList[index]);
                        }
                    }
                    this.usersList = this.AuthrisedUsers;
                } else if (this.subscribedParam == 'UnAuthrisedUsers') {
                    for (let index = 0; index < this.usersList.length; index++) {
                        if (this.usersList[index].adminAuth == 'I') {
                            this.UNAuthrisedUsers.push(this.usersList[index]);

                        }
                    }
                    this.usersList = this.UNAuthrisedUsers;
                }

            },
            error => {
                this.spinner.hide();
            }
        )
    }

    getairlinedata() {
        debugger;
        this.showUser = true;
        this.showReports = false
        debugger;
        this.spinner.show();
        this.superAdminservice.viewUserandReports(this.comapanydetails.orgId).subscribe(
            data => {
                debugger;
                this.spinner.hide();
                this.usersList = [];
                this.AuthrisedUsers = [];
                this.UNAuthrisedUsers = [];
                this.usersList = data.userDetails;
                if (this.subscribedParam == 'All') {
                    this.usersList = data.userDetails;
                } else if (this.subscribedParam == 'AuthrisedUsers') {
                    console.log(this.usersList[0].adminAuth);
                    for (let index = 0; index < this.usersList.length; index++) {
                        console.log(this.usersList[index].adminAuth);
                        if (this.usersList[index].adminAuth == 'A') {
                            this.AuthrisedUsers.push(this.usersList[index]);
                        }
                    }
                    this.usersList = this.AuthrisedUsers;
                } else if (this.subscribedParam == 'UnAuthrisedUsers') {
                    this.UNAuthrisedUsers = [];
                    for (let index = 0; index < this.usersList.length; index++) {
                        if (this.usersList[index].adminAuth == 'I') {
                            this.UNAuthrisedUsers.push(this.usersList[index]);

                        }
                    }
                    this.usersList = this.UNAuthrisedUsers;
                }

            },
            error => {
                this.spinner.hide();
            }
        )
    }

    Service() {
        debugger;
        if(this.ViewReportsData.isfromSideBarViewReports === 'yes'){
            this.orgIdValue = this.Object.orgId;
            this.ViewReportsData.isfromSideBarViewReports = 'no'
        }else{
            this.orgIdValue = this.comapanydetails.orgId;
        }
        this.OrgId = JSON.parse(localStorage.getItem('organizationId'));
        this.spinner.show();
        this.superAdminservice.viewUserandReports(this.orgIdValue).subscribe(
            data => {
                debugger;
                this.spinner.hide();
                this.usersList = data.userDetails;
                this.reportsList = [];
                this.reportsList = data.userReports;
                console.log(this.usersList);
                console.log(this.reportsList);
                this.finalReportArray = [];
                this.mergedReports = [];

                if (data.orgType === 'H') {
                    for (let i = 0; i < this.usersList.length; i++) {
                        debugger;
                        for (let j = 0; j < this.reportsList.length; j++) {
                            if (this.usersList[i].userId == this.reportsList[j].userId) {
                                this.reportsList[j].firstName = this.usersList[i].firstName;
                                this.reportsList[j].jobTitle = this.usersList[i].jobTitle;
                                this.finalReportArray.push(this.reportsList[j]);
                            }
                        }
                    }
                } else {
                  for (let i = 0; i < this.reportsList.length; i++) {
                        if (this.reportsList[i].reportType === 'E') {
                            this.finalReportArray.push(this.reportsList[i]);
                        }
                    }
                }

                for (let i = 0; i < this.usersList.length; i++) {
                    if (this.usersList[i].userStatus === 'A') {
                        this.usersList[i].userStatus = 'Active';
                    } else if (this.usersList[i].userStatus === 'I') {
                        this.usersList[i].userStatus = 'Inactive';
                    }
                }

                for (let index = 0; index < this.finalReportArray.length; index++) {
                    if (this.finalReportArray[index].emailStatus === 'N' && this.finalReportArray[index].schedulingTime != null) {
                        this.finalReportArray[index].emailStatus = 'finalTimer';
                    } else if (this.finalReportArray[index].emailStatus === 'S') {
                        this.finalReportArray[index].emailStatus = 'Sent';
                    }
                    else if (this.finalReportArray[index].emailStatus === 'ST') {
                        this.finalReportArray[index].emailStatus = 'finalTimer';
                    } else if (this.finalReportArray[index].emailStatus === 'H') {
                        this.finalReportArray[index].emailStatus = 'Hold';
                    }
                    else if (this.finalReportArray[index].emailStatus === 'C') {
                        this.finalReportArray[index].emailStatus = 'Cancelled';
                    } else if (this.finalReportArray[index].emailStatus === 'N' && this.finalReportArray[index].schedulingTime === null) {
                        this.finalReportArray[index].emailStatus = 'authorisation failed';
                    }

                }
                console.log('Array', this.finalReportArray);

                for (var i = 0; i < this.finalReportArray.length; i++) {
                    var status = this.containsObject(this.finalReportArray[i], this.mergedReports);
                    if (status) {
                        var idx = this.mergedReports.findIndex(c => c.masterAwbNo == this.finalReportArray[i].masterAwbNo);
                        this.mergedReports[idx].type = this.finalReportArray[i].reportType;
                        this.mergedReports[idx].extrenalpath = this.finalReportArray[i].reportPath;
                        this.mergedReports[idx].emailStatus = this.finalReportArray[i].emailStatus;

                    } else {
                        // this.mergedReports = [];
                        this.mergedReports.push(this.finalReportArray[i]);

                    }
                };
                debugger;
               for (var i = 0; i < this.mergedReports.length; i++) {
                    if (this.mergedReports[i].extrenalpath) {
                        if (this.mergedReports[i].type === 'E') {
                            let epath = this.mergedReports[i].extrenalpath;
                            let externalPath = epath.split("/");
                            this.mergedReports[i].externalFileName = externalPath[1];
                        } else {
                            let epath = this.mergedReports[i].extrenalpath;
                            let internalPath = epath.split("/");
                            this.mergedReports[i].internalFileName = internalPath[1];
                        }
                    }
                    if (this.mergedReports[i].reportPath) {
                        if (this.mergedReports[i].reportType === 'E') {
                            let ipath = this.mergedReports[i].reportPath;
                            let externalPath = ipath.split("/");
                            this.mergedReports[i].externalFileName = externalPath[1];
                        } else {
                            let ipath = this.mergedReports[i].reportPath;
                            let internalPath = ipath.split("/");
                            this.mergedReports[i].internalFileName = internalPath[1];
                        }
                    }
                }
                if (this.subscribedParam == 'All') {
                    this.mergedReports = this.mergedReports;
                } else if (this.subscribedParam == 'AuthrisedUsers') {
                     this.AuthUserReport = [];
                    for (let i = 0; i < this.usersList.length; i++) {
                        for (let j = 0; j < this.mergedReports.length; j++) {
                            if (this.usersList[i].userId == this.mergedReports[j].userId) {
                                if (this.usersList[i].adminAuth == 'A') {
                                    this.AuthUserReport.push(this.mergedReports[j]);
                                }
                            }

                        }
                    }
                    this.mergedReports = this.AuthUserReport;
                } else if (this.subscribedParam == 'UnAuthrisedUsers') {
                    for (let i = 0; i < this.usersList.length; i++) {
                        for (let j = 0; j < this.mergedReports.length; j++) {
                            if (this.usersList[i].userId == this.mergedReports[j].userId) {
                                if (this.usersList[i].adminAuth == 'I') {
                                    this.UnAuthUserReport.push(this.mergedReports[j]);
                                }
                            }
                        }
                    }
                    this.mergedReports = this.UnAuthUserReport;
                }
                if (data.responseCode == '1') {
                }
                else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });
                    this.spinner.hide();
                }

                console.log("before", this.mergedReports)
                console.log("after", this.mergedReports)
                console.log(this.mergedReports);
            },
            error => {
                this.spinner.hide();
            }

        )
    }

    stationsNavigation(){
        this.ViewReportsData.isFromViewUsersStations = 'yes';
         this.router.navigate(["viewstations"]);
    }

}
