import { Injectable } from '@angular/core';
import { CanActivate,Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import{LoginService } from '../services/login/login.service'

@Injectable({
  providedIn: 'root'
})
export class AuthenticateGuard implements CanActivate {
 constructor(private LoginService: LoginService,private Router: Router) { }

     canActivate(): boolean {
        debugger;
        // let token = localStorage.getItem('token')
        let loginresultobject = JSON.parse(localStorage.getItem('loginresultobject'));
        console.log(loginresultobject)
        if (loginresultobject != null) {
            debugger;
            return true;
        } else {
            debugger;
            this.Router.navigate(['/login'])
        }
    }

}
