// import { AbstractControl } from '@angular/forms';
// export class ConfirmPasswordValidator {
//   static MatchPassword(control: AbstractControl) {
//     let password = control.get('NewPassword').value;
//     let confirmPassword = control.get('ConfirmPassword').value;
//     if (password != confirmPassword ) {
//       control.get('ConfirmPassword').setErrors({ ConfirmPassword: true });
//     }
//     else {
//         // control.get('ConfirmPassword').setErrors({ required: false });
//       return null;
//     }
//     if(confirmPassword === ""){
//         control.get('ConfirmPassword').setErrors({ ConfirmPassword: false });
//         control.get('ConfirmPassword').setErrors({ required: true });
//     }
//   }
// }



import { AbstractControl } from '@angular/forms';
export class ConfirmPasswordValidator {

    static MatchPassword(AC: AbstractControl) {
        let password = AC.get('NewPassword').value;
        if(AC.get('ConfirmPassword').touched || AC.get('ConfirmPassword').dirty) {
            let verifyPassword = AC.get('ConfirmPassword').value;

            if(password != verifyPassword) {
                AC.get('ConfirmPassword').setErrors( {ConfirmPassword: true} )
            } else {
                return null
            }
        }
    }
}