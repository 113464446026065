import { Component, OnInit } from '@angular/core';
import { StationService } from '../../../services/station/station.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
@Component({
    selector: 'app-addstations',
    templateUrl: './addstations.component.html',
    styleUrls: ['./addstations.component.css']
})
export class AddstationsComponent implements OnInit {
    Logindetails: any;
    addStationForm: FormGroup;
    airportDetails = [];
    stationcodeData: any;
    showListstation: boolean;
    items: any;
    stationCode: any;
    comapanydetails: any;
    orgId: any;
    constructor(private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService, private stationService: StationService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder) { }
    initilizeStationCodeData() {
        this.items = JSON.parse(localStorage.getItem('stationsList'));
        //this.droplist=true;
    }
    getstationItems(ev: any) {
        debugger;
        // Reset items back to all of the items
        this.initilizeStationCodeData()
        // set val to the value of the searchbar
        const val = ev;
        // if the value is an empty string don't filter the items
        if (val && val.trim() !== '') {
            this.initilizeStationCodeData()
            this.stationcodeData = this.items.filter((item) =>
                item.code.toLowerCase().startsWith(val.toLowerCase())
            );
            if (this.stationcodeData.length === 0) {

                this.stationcodeData = this.stationcodeData.filter((item) =>
                    item.code.toLowerCase().startsWith(val.toLowerCase())
                );
            }
            // Show the results
            this.showListstation = true;
        } else {
            // hide the results when the query is empty
            this.showListstation = false;
        }
    }
    selectstationcode(item) {
        debugger;
        // this.stationCode =
        let val = item.code + '-' + item.name;
        // this.addStationForm.setItem('stationCode') =item.code;
        this.addStationForm.get('stationCode').setValue(item.code);
        this.addStationForm.get('stationCode2').setValue(val);
        this.showListstation = false;
    }
    ngOnInit() {
        debugger;
        this.spinner.show();
        this.stationService.GetStationCodes().subscribe(
            data => {
                this.airportDetails = data.airport_details
                debugger;
                localStorage.setItem('stationsList', JSON.stringify(this.airportDetails));
                console.log(data)
                this.spinner.hide();
            },
            error => {
                this.spinner.hide();
            }
        )

        this.addStationForm = this.formBuilder.group({
            'contactName': new FormControl(''),
            'stationCode': new FormControl('', [Validators.required]),
            'stationCode2': new FormControl(''),
            'emailId': new FormControl('', [Validators.required, Validators.email]),
            'address': new FormControl('', [Validators.required]),
            'latitude': new FormControl(''),
            'longitude': new FormControl(''),
        })

    }

    addStation() {
        debugger;
        console.log(this.addStationForm)
        this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));

        if (this.Logindetails.userType === 'SA') {
            this.orgId = this.comapanydetails.orgId;
        } else {
            this.orgId = this.Logindetails.orgId;
        }
        const obj = {
            orgId:  this.orgId,
            "contactName": this.addStationForm.value.contactName,
            "stationCode": this.addStationForm.value.stationCode,
            "stationCode2": this.addStationForm.value.stationCode2,
            "emailId": this.addStationForm.value.emailId,
            "address": this.addStationForm.value.address,
            'latitude': this.addStationForm.value.latitude,
            "longitude": this.addStationForm.value.longitude

        }
        console.log(obj)
        this.spinner.show();
        this.stationService.AddStations(obj).subscribe(
            data => {
                debugger;
                console.log(data)
                this.spinner.hide();
                if (data.status == '1') {
                    this.toastr.success('Successfully created', '', { timeOut: 1500 });
                    this.router.navigateByUrl('/viewstations');
                }
                else {
                    this.toastr.error(data.message, '', {});
                }
            },
            error => {
                this.spinner.hide();
            }
        )
    }
    gotoback() {
        this.router.navigateByUrl('/viewstations');
    }
}
