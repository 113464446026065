import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewReportsData } from '../../data/DataShareViewDetails';
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    searchItem: any;
    Allp = 1;
    AllpageIndex = 0;
    AllpageSize = 4;
    AlllowValue = 0;
    AllhighValue = 4;

    authp = 1;
    authpageIndex = 0;
    authpageSize = 4;
    authlowValue = 0;
    authhighValue = 4;

    unauthp = 1;
    unauthpageIndex = 0;
    unauthpageSize = 4;
    unauthlowValue = 0;
    unauthhighValue = 4;

    premiump = 1;
    premiumpageIndex = 0;
    premiumpageSize = 4;
    premiumlowValue = 0;
    premiumhighValue = 4;

    nonpremiump = 1;
    nonpremiumpageIndex = 0;
    nonpremiumpageSize = 4;
    nonpremiumlowValue = 0;
    nonpremiumhighValue = 4;

    Obj: any;
    role: string;
    authorizedOrgs = [];
    unAuthorizedOrgs = [];
    premiumOrgs = [];
    nonPremiumOrgs = [];
    allAUOrgs = [];
    allPNOrgs = [];

    search_Allorgs = [];
    serach_authorgs = [];
    serach_unauthorgs = [];
    serach_premium = [];
    serach_nonpremium = [];

    allStatus: boolean;
    authStatus: boolean;
    nonauthStatus: boolean;
    premiumStatus: boolean;
    nonpremiumStatus: boolean;
    emailid: any;
    Password: any;
    usertype: any;
    Organizations: any;
    Object: any;
    userName: any;
    organizationDetails: any;
    recentActivites: any;
    loginDetails: any;
    LoginDetails: any;
    comapanydetails: any;
    orgType: any;
    constructor(private ViewReportsData: ViewReportsData, private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService, private superAdminservice: SuperadminService, private spinner: NgxSpinnerService) { }

    ngOnInit() {
        this.userName = JSON.parse(localStorage.getItem('loginresultobject'));
    }

    gotosetting() {

        this.router.navigateByUrl('/settings');
    }
    gotoviewReports(type) {
        debugger;
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        if (type === 'report') {
            this.ViewReportsData.isfromSideBarViewReports = "yes"
        }

        this.router.navigateByUrl('/viewusers');
    }
    gotoDashBoard() {
        debugger;
        this.userName = JSON.parse(localStorage.getItem('loginresultobject'));
        if (this.userName.userType === 'SA') {
            this.router.navigateByUrl('/sa-dashboard');
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(['/sa-dashboard']));
        } else if (this.userName.userType === 'S' && this.userName.orgType === 'H') {
            this.router.navigateByUrl('/csa-dashboard');
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(['/csa-dashboard']));
        }
        else if (this.userName.userType === 'E') {
            this.router.navigateByUrl('/user-dashboard');
             this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(['/user-dashboard']));

        }
        else if (this.userName.userType == 'S' && this.userName.orgType === 'A') {
            this.router.navigateByUrl('/csa-airline-dashboard');
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(['/csa-airline-dashboard']));

        }else{
            // this.serviceHit()
        }
    }

    // serviceHit() {
    //     debugger;
    //     if (this.userName.userType === 'SA') {
    //         this.Obj = JSON.parse(localStorage.getItem('loginresultobject'));
    //         this.emailid = this.Obj.emailId;
    //         this.Password = this.loginDetails;
    //         this.usertype = this.Obj.userType;
    //         this.spinner.show();
    //         this.superAdminservice.getSuperAdminDashBoard(this.emailid, this.usertype).subscribe(
    //             data => {
    //                 console.log(data)
    //                 this.spinner.hide();
    //                 if (data.responseCode == '1') {
    //                     // this.toastr.success('Login Successfully', '', {});
    //                     localStorage.setItem('saDashBoardData', JSON.stringify(data));
    //                     this.Object = data;
    //                     this.Organizations = data.organizationDetails;
    //                 }
    //                 else {
    //                     this.toastr.error(data.message, '', {});
    //                 }
    //             },
    //             error => {
    //                 this.spinner.hide();
    //             }
    //         )
    //     } else if (this.userName.userType === 'S') {
    //         this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
    //         this.emailid = this.Object.emailId;
    //         this.Password = this.Object.password;
    //         this.usertype = this.Object.userType;
    //         this.orgType = this.Object.orgType;
    //         this.spinner.show();
    //         this.superAdminservice.getCompanySuperAdminDashBoard(this.emailid, this.usertype, this.orgType).subscribe(
    //             data => {
    //                 console.log(data)
    //                 this.spinner.hide();
    //                 if (data.responseCode == '1') {
    //                     // this.toastr.success('Login Successfully', '', {});
    //                     localStorage.setItem('caDashBoardData', JSON.stringify(data));
    //                     this.Obj = data;
    //                     this.Organizations = data.organizationDetails;
    //                 }
    //                 else {
    //                     this.toastr.error(data.message, '', {});
    //                 }
    //             },
    //             error => {
    //                 this.spinner.hide();
    //             }
    //         )
    //     }
    //     else if (this.userName.userType == 'E') {
    //         this.Object = JSON.parse(localStorage.getItem('loginresultobject'));
    //         this.emailid = this.Object.emailId;
    //         this.Password = this.Object.password;
    //         this.usertype = this.Object.userType;
    //         this.spinner.show();
    //         this.superAdminservice.getUserDashBoard(this.emailid, this.usertype).subscribe(
    //             data => {
    //                 console.log(data)
    //                 this.spinner.hide();
    //                 if (data.responseCode == '1') {
    //                     //this.toastr.success('Login Successfully', '', {});
    //                     localStorage.setItem('userDashBoardData', JSON.stringify(data));
    //                     this.organizationDetails = data.organizationDetails;
    //                     this.recentActivites = data.recentActivites;
    //                 }
    //                 else {
    //                     this.toastr.error(data.message, '', {});
    //                 }
    //             },
    //             error => {
    //                 this.spinner.hide();
    //             }
    //         )
    //     }
    // }

    navigateToReports() {
        this.router.navigate(["viewusers"]);
        this.ViewReportsData.isFrmViewDetails = "yes"
    }

     navigateToStations(){
        //  if()
         this.router.navigate(["viewstations"]);
          this.ViewReportsData.isfromSidebarStations = 'yes'
     }

}
