import { Injectable } from '@angular/core';

@Injectable()
export class ViewReportsData {
  isFrmViewDetails: string = 'no';
  isfromSideBarViewReports = 'no';
  isfromairlineCsaDashboard = 'no';
  isfromHandlerCsaDashboard = 'no';
  isfromViewReports = 'no';
  isfromSidebarStations = 'no';
  isFromViewReportsStations = 'no';
  isFromViewUsersStations = 'no';
}