import { Component, OnInit } from '@angular/core';
// import {MyAccountService } from '../../services/myAccount/my-account.service'


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  userName: any;
  myAccount;

  public role: string;
    Logindetails: any;
    userId: string;
    saDashboard:any;
    DashBoardDetails:any;
    LoginDetails: any;
  constructor() { }

  ngOnInit() {

debugger;

    this.LoginDetails = JSON.parse(localStorage.getItem('loginresultobject'));
    console.log(this.LoginDetails)
    if (this.LoginDetails.userType === 'SA') {
      this.role = 'Super Admin';

    }else if (this.LoginDetails.userType === 'S') {
         this.role = 'Company Super Admin';
    }else if (this.LoginDetails.userType  == 'E') {
      this.role = 'User';
    }
//  this.DashBoardDetails = JSON.parse(localStorage.getItem('DashBoardData'));

  }

  logOut(){
      debugger;
      localStorage.clear();
  }


}
