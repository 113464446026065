import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminService } from '../../services/superadmin/superadmin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeDetectorRef } from '@angular/core';

import { LoginDataService } from '../../login/login-data.service';

declare var jQuery: any;
@Component({
    selector: 'app-sa-dashboard',
    templateUrl: './sa-dashboard.component.html',
    styleUrls: ['./sa-dashboard.component.css']
})
export class SaDashboardComponent implements OnInit {
    dummyObj: any;
    searchItem: any;
    Allp = 1;
    AllpageIndex = 0;
    AllpageSize = 4;
    AlllowValue = 0;
    AllhighValue = 4;

    authp = 1;
    authpageIndex = 0;
    authpageSize = 4;
    authlowValue = 0;
    authhighValue = 4;

    unauthp = 1;
    unauthpageIndex = 0;
    unauthpageSize = 4;
    unauthlowValue = 0;
    unauthhighValue = 4;

    premiump = 1;
    premiumpageIndex = 0;
    premiumpageSize = 4;
    premiumlowValue = 0;
    premiumhighValue = 4;

    nonpremiump = 1;
    nonpremiumpageIndex = 0;
    nonpremiumpageSize = 4;
    nonpremiumlowValue = 0;
    nonpremiumhighValue = 4;



    // <----airlines---->
    Allair = 1;
    AllairpageIndex = 0;
    AllairpageSizes = 4;
    AllairlowValues = 0;
    AllairhighValues = 4;


    authair = 1;
    authpageIndexs = 0;
    authpageSizes = 4;
    authlowValues = 0;
    authhighValues = 4;

    unauthpair = 1;
    unauthpageIndexs = 0;
    unauthpageSizes = 4;
    unauthlowValues = 0;
    unauthhighValues = 4;

    premiumpair = 1;
    premiumpageIndexs = 0;
    premiumpageSizes = 4;
    premiumlowValues = 0;
    premiumhighValues = 4;

    nonpremiumpair = 1;
    nonpremiumpageIndexs = 0;
    nonpremiumpageSizes = 4;
    nonpremiumlowValues = 0;
    nonpremiumhighValues = 4;





    Obj: any;
    role: string;
    authorizedOrgs = [];
    unAuthorizedOrgs = [];
    premiumOrgs = [];
    nonPremiumOrgs = [];
    allAUOrgs = [];
    allPNOrgs = [];

    search_Allorgs = [];
    serach_authorgs = [];
    serach_unauthorgs = [];
    serach_premium = [];
    serach_nonpremium = [];


    search_Allairorgs = [];
    serach_authairorgs = [];
    serach_unauthairorgs = [];
    serach_premiumair = [];
    serach_nonpremiumair = [];


    allStatus: boolean;
    authStatus: boolean;
    nonauthStatus: boolean;
    premiumStatus: boolean;
    nonpremiumStatus: boolean;
    emailid: any;
    Password: any;
    usertype: any;
    Organizations: any;
    Object: any;
    activateObj: { "organizationId": any; "status": string; };
    loginDetails: any;
    delObj: { "orgId": any; "orgType": any; };
    Organizationsairlines: any;
    allairlinesOrgs: any;
    allPNairlinesOrgs: any;
    authorizedairlineOrgs: any;
    unAuthorizedairlineOrgs: any;
    premiumairlineOrgs: any;
    nonPremiumairlineOrgs: any;
    allStatusair: boolean;
    authStatusair: boolean;
    nonauthStatusair: boolean;
    premiumStatusair: boolean;
    nonpremiumStatusair: boolean;
    searchItems: string;
    authObj: {};
    constructor(private cd: ChangeDetectorRef,
        private LoginDataService: LoginDataService,
        private readonly route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService, private superAdminservice: SuperadminService, private spinner: NgxSpinnerService) { }

    ngOnInit() {
        debugger;

        this.Obj = JSON.parse(localStorage.getItem('loginresultobject'));
        this.emailid = this.Obj.emailId;
        this.Password = this.loginDetails;
        this.usertype = this.Obj.userType;
        this.allairlinesOrgs = [];
        this.allPNairlinesOrgs = [];
        this.authorizedairlineOrgs = [];
        this.unAuthorizedairlineOrgs = [];
        this.premiumairlineOrgs = [];
        this.nonPremiumairlineOrgs = [];
        this.allStatusair = true;
        this.authStatusair = false;
        this.nonauthStatusair = false;
        this.premiumStatusair = false;
        this.nonpremiumStatusair = false;

        this.spinner.show();
        this.superAdminservice.getSuperAdminDashBoard(this.emailid, this.usertype).subscribe(
            data => {
                console.log(data)
                this.spinner.hide();
                if (data.responseCode == '1') {
                    debugger;
                    // this.toastr.success('Login Successfully', '', {});
                    localStorage.setItem('saDashBoardData', JSON.stringify(data));
                    localStorage.setItem('DashBoardData', JSON.stringify(data));
                    this.Object = data;
                    this.Organizations = data.organizationDetails;

                    for (let index = 0; index < this.Organizations.length; index++) {

                        if (this.Organizations[index].authStatus == 'A') {
                            this.allAUOrgs.push(this.Organizations[index]);

                        } else if (this.Organizations[index].authStatus == 'I') {
                            this.allAUOrgs.push(this.Organizations[index]);
                        }
                    }
                    for (let index = 0; index < this.Organizations.length; index++) {
                        if (this.Organizations[index].premiumUser == 'A') {
                            this.allPNOrgs.push(this.Organizations[index]);
                        } else if (this.Organizations[index].premiumUser == 'I') {
                            this.allPNOrgs.push(this.Organizations[index]);
                        }
                    }
                    for (let index = 0; index < this.Organizations.length; index++) {

                        if (this.Organizations[index].authStatus == 'A') {
                            this.authorizedOrgs.push(this.Organizations[index]);
                        } else if (this.Organizations[index].authStatus == 'I') {
                            this.unAuthorizedOrgs.push(this.Organizations[index]);
                        }
                    }
                    for (let index = 0; index < this.Organizations.length; index++) {

                        if (this.Organizations[index].premiumUser == 'A') {
                            this.premiumOrgs.push(this.Organizations[index]);
                        } else if (this.Organizations[index].premiumUser == 'I' || this.Organizations[index].premiumUser == '' || this.Organizations[index].premiumUser == 'N') {
                            this.nonPremiumOrgs.push(this.Organizations[index]);
                        }
                    }

                    // -------airlines----//

                    this.Organizationsairlines = data.airlinesOrganizationDetails;

                    for (let index = 0; index < this.Organizationsairlines.length; index++) {

                        if (this.Organizationsairlines[index].authStatus == 'A') {
                            this.allairlinesOrgs.push(this.Organizationsairlines[index]);

                        } else if (this.Organizationsairlines[index].authStatus == 'I') {
                            this.allairlinesOrgs.push(this.Organizationsairlines[index]);
                        }
                    }

                    for (let index = 0; index < this.Organizationsairlines.length; index++) {
                        if (this.Organizationsairlines[index].premiumUser == 'A') {
                            this.allPNairlinesOrgs.push(this.Organizationsairlines[index]);
                        } else if (this.Organizationsairlines[index].premiumUser == 'I') {
                            this.allPNairlinesOrgs.push(this.Organizationsairlines[index]);
                        }
                    }
                    for (let index = 0; index < this.Organizationsairlines.length; index++) {

                        if (this.Organizationsairlines[index].authStatus == 'A') {
                            this.authorizedairlineOrgs.push(this.Organizationsairlines[index]);
                        } else if (this.Organizationsairlines[index].authStatus == 'I') {
                            this.unAuthorizedairlineOrgs.push(this.Organizationsairlines[index]);
                        }
                    }
                    for (let index = 0; index < this.Organizationsairlines.length; index++) {

                        if (this.Organizationsairlines[index].premiumUser == 'A') {
                            this.premiumairlineOrgs.push(this.Organizationsairlines[index]);
                        } else if (this.Organizationsairlines[index].premiumUser == 'I' || this.Organizationsairlines[index].premiumUser == '' || this.Organizationsairlines[index].premiumUser == 'N') {
                            this.nonPremiumairlineOrgs.push(this.Organizationsairlines[index]);
                        }
                    }


                    // -------airlines end----//


                    this.search_Allorgs = this.allAUOrgs;
                    this.serach_authorgs = this.authorizedOrgs;
                    this.serach_unauthorgs = this.unAuthorizedOrgs;
                    this.serach_premium = this.premiumOrgs;
                    this.serach_nonpremium = this.nonPremiumOrgs;
                    this.tabChange('All');




                    // -------airlines search----//
                    this.search_Allairorgs = this.allairlinesOrgs;
                    this.serach_authairorgs = this.authorizedairlineOrgs;
                    this.serach_unauthairorgs = this.unAuthorizedairlineOrgs;
                    this.serach_premiumair = this.premiumairlineOrgs;
                    this.serach_nonpremiumair = this.nonPremiumairlineOrgs;
                    this.tabChanges('Allairline');
                    // -------airlines end----//



                }
                else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });
                }
            },
            error => {
                this.spinner.hide();
            }
        )
        let storedActiveTab = localStorage.getItem('ActiveTab');
        if (storedActiveTab) {
            this.tabChange(storedActiveTab);
        } else {
            this.tabChange('All');
        }

        let storedActiveTab1 = localStorage.getItem('ActiveTabs');
        if (storedActiveTab1) {
            this.tabChanges(storedActiveTab1);
        } else {
            this.tabChanges('Allairline');
        }

    }
config = {
animated: true,
keyboard: true,
backdrop: false,
ignoreBackdropClick: true
};



    handleActivation(event, obj) {
        debugger;
        jQuery('#myModal').modal({ backdrop: 'static', keyboard: false,ignoreBackdropClick: true });
        this.dummyObj = obj;
        if (event.target.checked) {
            this.activateObj = {
                "organizationId": obj.orgId,
                "status": "A"
            }
            this.dummyObj.status = 'A'
        } else {
            this.activateObj = {
                "organizationId": obj.orgId,
                "status": "I"
            }
            this.dummyObj.status = 'I'
        }
        console.log(event);
        debugger;

        console.log(obj);
    }


    authoriseYes(obj) {
        debugger;

        // this.spinner.show();
        this.superAdminservice.viewActivation(this.activateObj).subscribe(
            data => {
                // this.spinner.hide();
                if (data.responseCode == '1') {
                    this.toastr.success('Successfully updated', '', { timeOut: 1500 });
                    // this.ngOnInit();
                    // this.cd.detectChanges();
                    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
                    // this.router.navigate(['/sa-dashboard']));
                    jQuery('#myModal').modal('hide');
                }
                else {
                    this.toastr.error(data.message, '', { timeOut: 1500 });
                }
            },
            error => {
                this.spinner.hide();
            }
        )
    }



    authoriseNo(event, dummyObj) {
        debugger;
        // if(this.dummyObj.status == 'I'){
        // this.dummyObj.status = 'I'
        // }else if(this.dummyObj.status == 'A'){
        // this.dummyObj.status = 'A'
        // }
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['/sa-dashboard']));
        // this.cd.detectChanges();
        jQuery('#myModal').modal('hide');
    }

    tabChange(data) {
        this.searchItem = '';
        if (data == 'All') {
            localStorage.setItem('ActiveTab', 'All');
            // localStorage.setItem('ActiveTab', JSON.stringify('All'));
            this.allStatus = true;
            this.authStatus = false;
            this.nonauthStatus = false;
            this.premiumStatus = false;
            this.nonpremiumStatus = false;
            this.allAUOrgs = this.search_Allorgs;
        } else if (data == 'Auth') {
            localStorage.setItem('ActiveTab', 'Auth');
            //  localStorage.setItem('ActiveTab', JSON.stringify('Auth'));
            this.allStatus = false;
            this.authStatus = true;
            this.nonauthStatus = false;
            this.premiumStatus = false;
            this.nonpremiumStatus = false;
            this.authorizedOrgs = this.serach_authorgs;
        } else if (data == 'Unauth') {
            localStorage.setItem('ActiveTab', 'Unauth');
            //  localStorage.setItem('ActiveTab', JSON.stringify('Unauth'));
            this.allStatus = false;
            this.authStatus = false;
            this.nonauthStatus = true;
            this.premiumStatus = false;
            this.nonpremiumStatus = false;
            this.unAuthorizedOrgs = this.serach_unauthorgs;
        } else if (data == 'premium') {
            localStorage.setItem('ActiveTab', 'premium');
            //  localStorage.setItem('ActiveTab', JSON.stringify('premium'));
            console.log(this.premiumOrgs);
            this.allStatus = false;
            this.authStatus = false;
            this.nonauthStatus = false;
            this.premiumStatus = true;
            this.nonpremiumStatus = false;
            this.premiumOrgs = this.serach_premium;
        } else if (data == 'Nonpremium') {
            localStorage.setItem('ActiveTab', 'Nonpremium');
            //  localStorage.setItem('ActiveTab', JSON.stringify('Nonpremium'));
            this.allStatus = false;
            this.authStatus = false;
            this.nonauthStatus = false;
            this.premiumStatus = false;
            this.nonpremiumStatus = true;
            this.nonPremiumOrgs = this.serach_nonpremium;
        }
    }

    // <----airlines tabs--------->

    tabChanges(data) {
        this.searchItems = '';
        if (data == 'Allairline') {
            localStorage.setItem('ActiveTabs', 'Allairline');
            this.allStatusair = true;
            this.authStatusair = false;
            this.nonauthStatusair = false;
            this.premiumStatusair = false;
            this.nonpremiumStatusair = false;
            this.allairlinesOrgs = this.search_Allairorgs;
        } else if (data == 'Authairline') {
            localStorage.setItem('ActiveTabs', 'Authairline');
            this.allStatusair = false;
            this.authStatusair = true;
            this.nonauthStatusair = false;
            this.premiumStatusair = false;
            this.nonpremiumStatusair = false;
            this.authorizedairlineOrgs = this.serach_authairorgs;
        } else if (data == 'Unauthairline') {
            localStorage.setItem('ActiveTabs', 'Unauthairline');
            this.allStatusair = false;
            this.authStatusair = false;
            this.nonauthStatusair = true;
            this.premiumStatusair = false;
            this.nonpremiumStatusair = false;
            this.unAuthorizedairlineOrgs = this.serach_unauthairorgs;
        } else if (data == 'premiumairline') {
            localStorage.setItem('ActiveTabs', 'premiumairline');
            console.log(this.premiumOrgs);
            this.allStatusair = false;
            this.authStatusair = false;
            this.nonauthStatusair = false;
            this.premiumStatusair = true;
            this.nonpremiumStatusair = false;
            this.premiumairlineOrgs = this.serach_premiumair;
        } else if (data == 'Nonpremiumairline') {
            localStorage.setItem('ActiveTabs', 'Nonpremiumairline');
            this.allStatusair = false;
            this.authStatusair = false;
            this.nonauthStatusair = false;
            this.premiumStatusair = false;
            this.nonpremiumStatusair = true;
            this.nonPremiumairlineOrgs = this.serach_nonpremiumair;
        }
    }

    // <-------airlines tabs--------->



    // ----------airlines search----->
    searchairlines() {
        debugger;
        console.log(this.searchItems);
        if (this.allStatusair) {
            if (this.searchItems != '') {
                const val = this.searchItems;
                this.allairlinesOrgs = [];
                this.allairlinesOrgs = this.search_Allairorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })

            } else {
                this.allairlinesOrgs = this.search_Allairorgs;
            }
        } else if (this.allStatusair) {
            if (this.searchItems != '') {
                const val = this.searchItems;
                this.authorizedairlineOrgs = [];
                this.authorizedairlineOrgs = this.serach_authairorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })

            } else {
                this.authorizedairlineOrgs = this.serach_authairorgs;
            }
        } else if (this.nonauthStatusair) {
            if (this.searchItems != '') {
                const val = this.searchItems;
                this.unAuthorizedairlineOrgs = [];
                this.unAuthorizedairlineOrgs = this.serach_unauthairorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
            } else {
                this.unAuthorizedairlineOrgs = this.serach_unauthairorgs;
            }
        }
        else if (this.premiumStatusair) {
            if (this.searchItems != '') {
                const val = this.searchItems;
                this.premiumairlineOrgs = [];
                this.premiumairlineOrgs = this.serach_premiumair.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
            } else {
                this.premiumairlineOrgs = this.serach_premiumair;
            }
        }
        else if (this.nonpremiumStatusair) {
            if (this.searchItems != '') {
                const val = this.searchItems;
                this.nonPremiumairlineOrgs = [];
                this.nonPremiumairlineOrgs = this.serach_nonpremiumair.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })

            } else {
                this.nonPremiumairlineOrgs = this.serach_nonpremiumair;

            }
        }
    }


    clearSearchs() {
        this.searchItems = '';
        if (this.allStatusair) {
            this.allairlinesOrgs = this.search_Allairorgs;
        } else if (this.authStatus) {
            this.authorizedairlineOrgs = this.serach_authairorgs;
        } else if (this.nonauthStatus) {
            this.unAuthorizedairlineOrgs = this.serach_unauthairorgs;
        }
        else if (this.premiumStatus) {
            this.premiumairlineOrgs = this.serach_premiumair;
        }
        else if (this.nonpremiumStatus) {
            this.nonPremiumOrgs = this.serach_nonpremiumair;
        }
    }

    // ---------airlines search---------->


    searchOrgs() {
        debugger;
        console.log(this.searchItem);
        if (this.allStatus) {
            if (this.searchItem != '') {
                const val = this.searchItem;
                this.allAUOrgs = [];
                this.allAUOrgs = this.search_Allorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })

            } else {
                this.allAUOrgs = this.search_Allorgs;
            }
        } else if (this.authStatus) {
            if (this.searchItem != '') {
                const val = this.searchItem;
                this.authorizedOrgs = [];
                this.authorizedOrgs = this.serach_authorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })

            } else {
                this.authorizedOrgs = this.serach_authorgs;
            }
        } else if (this.nonauthStatus) {
            if (this.searchItem != '') {
                const val = this.searchItem;
                this.unAuthorizedOrgs = [];
                this.unAuthorizedOrgs = this.serach_unauthorgs.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
            } else {
                this.unAuthorizedOrgs = this.serach_unauthorgs;
            }
        }
        else if (this.premiumStatus) {
            if (this.searchItem != '') {
                const val = this.searchItem;
                this.premiumOrgs = [];
                this.premiumOrgs = this.serach_premium.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })
            } else {
                this.premiumOrgs = this.serach_premium;
            }
        }
        else if (this.nonpremiumStatus) {
            if (this.searchItem != '') {
                const val = this.searchItem;
                this.nonPremiumOrgs = [];
                this.nonPremiumOrgs = this.serach_nonpremium.filter((item) => {
                    return (item.orgName.toLowerCase().indexOf(val.toLowerCase()) > -1);
                })

            } else {
                this.nonPremiumOrgs = this.serach_nonpremium;

            }
        }
    }


    clearSearch() {
        this.searchItem = '';
        if (this.allStatus) {
            this.allAUOrgs = this.search_Allorgs;
        } else if (this.authStatus) {
            this.authorizedOrgs = this.serach_authorgs;
        } else if (this.nonauthStatus) {
            this.unAuthorizedOrgs = this.serach_unauthorgs;
        }
        else if (this.premiumStatus) {
            this.premiumOrgs = this.serach_premium;
        }
        else if (this.nonpremiumStatus) {
            this.nonPremiumOrgs = this.serach_nonpremium;
        }
    }

    // getUser(obj){
    //   debugger;
    //   localStorage.setItem('organizationId', JSON.stringify(obj.orgKey));
    //   this.router.navigateByUrl('/viewusers');
    // }
    getUser(obj) {
        debugger;
        console.log(obj.orgId);
        let comapanydetails = {
            orgId: obj.orgId,
            orgLogo: obj.orgLogo,
            orgName: obj.orgName,
            orgType: obj.orgType
        }
        localStorage.setItem('comapanydetails', JSON.stringify(comapanydetails));
        this.router.navigate(["viewusers", obj.orgId]);
    }

    //----airlines view user----//

    gotoviewUser(obj) {
        debugger;
        let comapanydetails = {
            orgId: obj.orgId,
            orgLogo: obj.orgLogo,
            orgName: obj.orgName,
            orgType: obj.orgType,
            isfrom: "airlines"
        }
        localStorage.setItem('comapanydetails', JSON.stringify(comapanydetails));
        debugger;
        this.router.navigate(["viewusers", obj.orgId]);


    }

    EditOganization(event) {
        debugger;
        console.log(event.target.value);
        this.router.navigateByUrl('/editorg');
    }

    fn_deleteOrg(event, obj) {
        debugger;
        jQuery('#deletePopUp').modal({ backdrop: 'static', keyboard: false });

        this.delObj = {
            "orgId": event.orgId,
            "orgType": event.orgType
        }

    }

    deleteOrg() {
        console.log(event)
        console.log(this.delObj);
        this.superAdminservice.deleteOrg(this.delObj).subscribe(
            data => {
                debugger;
                console.log(data);
                if (data.responseCode === 1) {
                    this.toastr.success(data.message);
                    jQuery('#deletePopUp').modal('hide');
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                        this.router.navigate(['/sa-dashboard']));
                } else {
                    this.toastr.error(data.message);
                    jQuery('#deletePopUp').modal('hide');
                }
            }
        )
    }

    handleAuth(event, obj) {
        debugger;
        console.log(event);
        console.log(obj);
        this.authObj = {
            organizationId: obj.orgId
        }
        jQuery('#myModalauth').modal({ backdrop: 'static', keyboard: false });

    }

    authenticateYes() {
        debugger;
        this.superAdminservice.OrgAuth(this.authObj).subscribe(data => {
            console.log(data)
            if (data.responseCode === 1) {
                this.toastr.success(data.message);
                jQuery('#myModalauth').modal('hide');
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                    this.router.navigate(['/sa-dashboard']));
            } else {
                this.toastr.error(data.message);
                jQuery('#myModalauth').modal('hide');
            }
        })
    }

}
