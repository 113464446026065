import { Component, OnInit, enableProdMode } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { StationService } from '../../../services/station/station.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
declare var jQuery: any;
import { ViewReportsData } from '../../../data/DataShareViewDetails';
import { ViewUsersComponent } from '../../view-users/view-users.component'

if (!/localhost/.test(document.location.host)) {
    enableProdMode();
}

@Component({
    selector: 'app-viewstations',
    templateUrl: './viewstations.component.html',
    styleUrls: ['./viewstations.component.css']
})
export class ViewstationsComponent implements OnInit {
    stationDetails: any;
    Logindetails: any;
    comapanydetails: any;
    obj: { orgId: any; };
    deleteObj: { orgId: any; orgType: any; stationCode: any; };
    constructor(private ViewUsersComponent: ViewUsersComponent, private ViewReportsData: ViewReportsData, private readonly route: ActivatedRoute, private router: Router, private toastr: ToastrService, private stationService: StationService, private spinner: NgxSpinnerService) { }
    dataGrid1: DxDataGridComponent;
    viewData: any;
    stationCode: any;
    ngOnInit() {
        debugger;
        this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));

        if (this.Logindetails.userType === 'SA') {

            this.obj = {
                orgId: this.comapanydetails.orgId
            }
        } else {
            this.obj = {
                orgId: this.Logindetails.orgId
            }
        }
        this.spinner.show();
        this.stationService.ViewStations(this.obj).subscribe(
            data => {
                debugger;
                console.log(data)
                this.stationDetails = data;
                localStorage.setItem('viewData', JSON.stringify(data));
                this.spinner.hide();
                if (data.status == '1') {

                }
                else {

                }
            },
            error => {
                this.spinner.hide();
            }
        )
    }
    deleteStation() {
        debugger;
        this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));
        this.comapanydetails = JSON.parse(localStorage.getItem('comapanydetails'));
        this.viewData = JSON.parse(localStorage.getItem("viewDataobj"));
        this.stationCode = this.viewData.stationCode;

        if (this.Logindetails.userType === 'SA') {
            // this.obj = this.comapanydetails.orgId;
            this.deleteObj = {
                orgId: this.comapanydetails.orgId,
                orgType: this.comapanydetails.orgType,
                stationCode: this.stationCode
            }
        } else {
            this.deleteObj = {
                orgId: this.Logindetails.orgId,
                orgType: this.Logindetails.orgType,
                stationCode: this.stationCode
            }
        }

        // this.Logindetails = JSON.parse(localStorage.getItem('loginresultobject'));

        // const obj = {
        //     orgId: this.Logindetails.orgId,
        //     orgType: this.Logindetails.orgType,
        //     stationCode: this.stationCode
        // }
        this.spinner.show();
        this.stationService.DeleteStations(this.deleteObj).subscribe(
            data => {
                debugger;
                console.log(data)
                this.spinner.hide();
                if (data.status == '1') {
                    jQuery('#deletePopUp').modal('hide');
                    this.toastr.success('Deleted successfully', '', { timeOut: 1500 });
                    this.ngOnInit();
                }
                else {
                    jQuery('#deletePopUp').modal('hide');
                    this.toastr.error(data.message, '', {});
                }
            },
            error => {
                this.spinner.hide();
            }
        )
    }
    editStation(object) {
        debugger;
        this.router.navigateByUrl('/editstations');
        localStorage.setItem('viewData', JSON.stringify(object));
    }

    fn_deletestation(event, obj) {
        debugger;
        jQuery('#deletePopUp').modal({ backdrop: 'static', keyboard: false });
        console.log(event)
        console.log(obj)
        localStorage.setItem('viewDataobj', JSON.stringify(event));
        // this.delObj = {
        //     "orgId": event.orgId,
        //     "orgType": event.orgType
        // }

    }

    stationsNavigation() {
        debugger;
        if (this.ViewReportsData.isfromSidebarStations === 'yes') {
            if (this.Logindetails.orgType === 'H') {
                this.router.navigate(["csa-dashboard"]);
                this.ViewReportsData.isfromSidebarStations = 'no'
            } else if (this.Logindetails.orgType === 'A') {
                this.router.navigate(["csa-airline-dashboard"]);
                this.ViewReportsData.isfromSidebarStations = 'no'
            }
        } else if (this.ViewReportsData.isFromViewUsersStations === 'yes') {
            if (this.ViewReportsData.isFrmViewDetails === 'yes') {
                this.ViewReportsData.isFrmViewDetails = 'yes';
                this.ViewReportsData.isFromViewUsersStations = 'no';
                this.router.navigate(["viewusers"]);
            } else {
                this.ViewReportsData.isFrmViewDetails = 'no';
                this.ViewReportsData.isFromViewUsersStations = 'no';
                this.router.navigate(["viewusers"]);
            }

        }
        // else if (this.ViewReportsData.isFromViewReportsStations === 'yes') {


        //     this.ViewReportsData.isFromViewReportsStations = 'no';
        //     this.router.navigate(["viewusers"]);

        // }


    }
}
