export class Login {
    constructor(
        public emailId: string,
        public password: string
    ) { }


   

}
