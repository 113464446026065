import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { ToastrModule } from 'ngx-toastr';
import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SuperadminComponent } from './superadmin/superadmin.component';
import { SaDashboardComponent } from './superadmin/sa-dashboard/sa-dashboard.component';
import { LoginService } from './services/login/login.service';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { CreateuserComponent } from './csa-admin/createuser/createuser.component';
import { CSAAdminComponent } from './csa-admin/csa-admin.component';
import { CsaDashboardComponent } from './csa-admin/csa-dashboard/csa-dashboard.component';
import { UsersComponent } from './users/users.component';
import { MyaccountComponent } from './shared/myaccount/myaccount.component';
import { SettingsComponent } from './shared/settings/settings.component';
import { ViewUsersComponent } from './shared/view-users/view-users.component';
import { ViewReprotsComponent } from './shared/view-reprots/view-reprots.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { DxDataGridModule, DxCheckBoxModule } from 'devextreme-angular';
import { SuperadminService } from './services/superadmin/superadmin.service';
import { ViewdetailsComponent } from './shared/viewdetails/viewdetails.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AddstationsComponent } from './shared/stations/addstations/addstations.component';
import { EditstationsComponent } from './shared/stations/editstations/editstations.component';
import { ViewstationsComponent } from './shared/stations/viewstations/viewstations.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthenticateGuard } from './AuthGuard/authenticate.guard';
import { CreateorgComponent } from './superadmin/createorg/createorg.component';
import { EditorgComponent } from './superadmin/editorg/editorg.component';
import { MatSelectModule } from '@angular/material/select';
import { ViewReportsData } from './data/DataShareViewDetails';
import { AddAirlineComponent } from './csa-admin/add-airline/add-airline.component';
import { CsaAirlineDashboardComponent } from './csa-admin/csa-airline-dashboard/csa-airline-dashboard.component';

import {
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatGridListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatStepperModule,
} from '@angular/material';
import { ViewAirlineEmailsComponent } from './csa-admin/view-airline-emails/view-airline-emails.component';

import { EdituserComponent } from './csa-admin/edituser/edituser.component';
import { UnauthroizedUsersComponent } from './shared/unauthroized-users/unauthroized-users.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    DashboardComponent,
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    SuperadminComponent,
    SaDashboardComponent,
    CreateuserComponent,
    CSAAdminComponent,
    CsaDashboardComponent,
    UsersComponent,
    MyaccountComponent,
    SettingsComponent,
    ViewReprotsComponent,
    ViewUsersComponent,
    ForgotpasswordComponent,
    ViewdetailsComponent,
    AddstationsComponent,
    EditstationsComponent,
    ViewstationsComponent,
    CreateorgComponent,
    EditorgComponent,
    AddAirlineComponent,
    CsaAirlineDashboardComponent,
    ViewAirlineEmailsComponent,
    EdituserComponent,
    UnauthroizedUsersComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    DxDataGridModule,
    DxCheckBoxModule,
    MatCardModule,
    MatExpansionModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    FormsModule, ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1,
    }),
  ],
//   exports: [
//     MatAutocompleteModule,
//   ],

  providers: [LoginService, SuperadminService, AuthenticateGuard,ViewReportsData,ViewUsersComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
