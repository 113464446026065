import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login/login.service';
import { Login } from './login';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';

import {LoginDataService} from './login-data.service';
import {ShareLoginDataService} from '../data/share-login-data.service'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    User = new Login('', '');
    errormsg: string;
    LoginForm: FormGroup;


    constructor(private ShareLoginDataService:ShareLoginDataService,private LoginDataService:LoginDataService, private router: Router, private toastr: ToastrService, private loginservice: LoginService, private spinner: NgxSpinnerService, ) { }

    ngOnInit() {
        this.LoginForm = new FormGroup({
            'emailId': new FormControl(null, [Validators.required, Validators.email]),
            'password': new FormControl(null, [Validators.required, this.removeSpaces])
        })

    }


    gotofpassword() {
        this.router.navigateByUrl('/forgotpassword');
    }

    Login(LoginForm) {
        debugger;
        console.log(this.LoginForm)
        this.spinner.show();
        this.loginservice.login({ LoginForm }).subscribe(
            data => {
                debugger;
                console.log(data)
                this.ShareLoginDataService.updateData(data);
                this.LoginDataService.emitLoginData(data)
                this.spinner.hide();
                if (data.responseCode == '1') {
                    this.toastr.success('Login Successfully', '', { timeOut: 1500 });
                    localStorage.setItem('loginresultobject', JSON.stringify(data));
                    if (data.userType == 'SA') {
                        this.router.navigateByUrl('/sa-dashboard');
                    } else if (data.userType == 'S'  && data.orgType == 'H') {
                        this.router.navigateByUrl('/csa-dashboard');
                    }
                    else if (data.userType == 'S' && data.orgType == 'A') {
                         this.router.navigateByUrl('/csa-airline-dashboard');
                    }
                    else if (data.userType == 'E') {
                        this.router.navigateByUrl('/user-dashboard');
                    }
                }
                else {
                    this.toastr.error(data.message, '',  { timeOut: 1500 });
                }
            },
            error => {
                this.spinner.hide();
            }
        )
    }

    removeSpaces(control: AbstractControl) {
        if (control && control.value && !control.value.replace(/\s/g, '').length) {
            control.setValue('');
        }
        return null;
    }


}
