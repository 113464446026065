import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
// import { MustMatch } from './fieldmatchcheck'
// import { matchingPasswords } from './fieldmatchcheck';
import { ConfirmPasswordValidator } from './password.validator';
// import { CONNREFUSED } from 'dns';
import { MyAccountService } from '../../services/myAccount/my-account.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { debug } from 'util';
import { NgxSpinnerService } from 'ngx-spinner';
declare var jQuery: any;
// import { MdAutocompleteTrigger} from '@angular/material';


import { StationService } from '../../services/station/station.service';

@Component({
    selector: 'app-myaccount',
    templateUrl: './myaccount.component.html',
    styleUrls: ['./myaccount.component.css']
})
// @ViewChild(MdAutocompleteTrigger) trigger;
export class MyaccountComponent implements OnInit {


    myAccount;
    Logindetails: any;
    myAccountDetails: any;
    formDirective;

    colorChangeone: string;
    colorChangetwo: string;
    colorChangethree: string;
    ChangePasswordForm: FormGroup;
    EditProfileForm: FormGroup;

    show: boolean = true;
    show1: any = true;
    viewreport: boolean;
    editeport: boolean;
    changerpass: boolean;
    LoginData: string;
    isReadOnly: boolean;
    isStationCodeReadOnly: boolean;
    isAddressReadOnly: boolean;
    OldEmail: any;
    isUpdatable: boolean;
    LogindetailsOne: any;
    stationCode: FormControl;
    stationsList: Observable<any[]>;
    states: any;
    stationsListObj: any;
    showAutocomplete: boolean;

    detailsbm2d() {
        this.show = !this.show;
    }
    detailskod2d() {
        this.show1 = !this.show1;
    }

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    constructor(private router: Router, private spinner: NgxSpinnerService, private StationService: StationService, private toastr: ToastrService, private breakpointObserver: BreakpointObserver, private formBuilder: FormBuilder, private MyAccountService: MyAccountService) {
        this.stationCode = new FormControl('', [Validators.required]);
        this.stationsList = this.stationCode.valueChanges
            .pipe(
                startWith(''),
                map(stateobj => stateobj ? this.filterStations(stateobj) : this.states)
            );
    }
    ngOnInit() {
        debugger;
        this.isReadOnly = true;
        this.LogindetailsOne = JSON.parse(localStorage.getItem('loginresultobject'));
        this.Logindetails = JSON.parse(localStorage.getItem('saDashBoardData'));
        console.log(this.Logindetails)
        console.log(this.LogindetailsOne);
        this.spinner.show();
        this.MyAccountService.myAccountDetails(this.LogindetailsOne.userId).subscribe(
            data => {
                this.spinner.hide();
                if (data != null) {
                    debugger;
                    console.log(data);
                    this.myAccountDetails = data.userDetails;
                    // this.stationCode.setValue(this.myAccountDetails.stationCode);
                    this.stationCode.setValue(this.myAccountDetails.stationCode);
                    // console.log('myaccountdetails' + this.myAccountDetails )
                    console.log(this.myAccountDetails)
                    this.EditProfileForm.setValue({
                        firstname: this.myAccountDetails.firstName,
                        lastname: this.myAccountDetails.lastName,
                        emailid: this.myAccountDetails.loginId,
                        stationcode: this.myAccountDetails.stationCode,
                        contactnumber: this.myAccountDetails.contactNumber,
                        address: this.myAccountDetails.address,
                    });
                    if (this.myAccountDetails.userType === 'SA') {
                        this.isStationCodeReadOnly = true;
                        this.isAddressReadOnly = true;
                    } else if (this.myAccountDetails.userType === 'S') {
                        this.isStationCodeReadOnly = false;
                        this.isAddressReadOnly = true;
                    }
                } else {
                    this.toastr.success('Data Unavailable', '', {})
                }
            },
        )


        console.log(this.myAccountDetails)
        this.colorChangeone = 'load-delay';
        // ------ViewProfile start
        // this.LoginData = JSON.parse(localStorage.getItem('loginresultobject'))
        // console.log(this.LoginData)
        // ------ViewProfile end
        this.viewreport = true;
        this.editeport = false;
        this.changerpass = false;


        // -------ChangePasword

        this.ChangePasswordForm = this.formBuilder.group({
            'OldPassword': new FormControl(null, [Validators.required]),
            'NewPassword': new FormControl(null, [Validators.required, Validators.pattern(".{6,}")]),
            'ConfirmPassword': new FormControl(null, [Validators.required,])
        },
            {
                validator: ConfirmPasswordValidator.MatchPassword
            }

        );
        // console.log('stationCode' ,this.stationCode)
        this.EditProfileForm = this.formBuilder.group({
            'firstname': new FormControl('', [Validators.required]),
            'lastname': new FormControl('', [Validators.required]),
            'emailid': new FormControl('', [Validators.required, Validators.email]),
            'stationcode': new FormControl(''),
            'contactnumber': new FormControl('', [Validators.required, Validators.pattern('[1-9]\\d{9}')]),
            'address': new FormControl(''),
        })

        console.log(this.LogindetailsOne);
        let obj = {
            'organizationId': this.LogindetailsOne.orgId
        }
        this.MyAccountService.getOrgStations(obj).subscribe(
            data => {
                debugger;
                console.log(data)
                this.stationsListObj = data
                debugger;
                // console.log(data)
            },
            error => {
                this.spinner.hide();
            }
        )
        console.log(this.stationsListObj);

        this.EditProfileForm.valueChanges.subscribe(data =>{
            debugger;
            console.log(this.EditProfileForm)
            console.log('stationCode',this.stationCode)
            this.EditProfileForm.setErrors(null);
        })
    }
    // Update User Form
    Update() {
        debugger;
        if (this.EditProfileForm.dirty || this.stationCode.dirty) {
            const UpdatedDetails = {
                "userId": this.LogindetailsOne.userId,
                "userType": this.LogindetailsOne.userType,
                "firstName": this.EditProfileForm.value.firstname,
                "lastName": this.EditProfileForm.value.lastname,
                "emailId": this.EditProfileForm.value.emailid,
                "contactNumber": this.EditProfileForm.value.contactnumber,
                "stationCode": this.EditProfileForm.value.stationcode,
                "address": this.EditProfileForm.value.address
            }
            this.spinner.show();
            this.MyAccountService.updateUserDetails(UpdatedDetails).subscribe(
                data => {
                    this.spinner.hide();
                    debugger;
                    console.log(data);
                    if (data.responseCode === 1) {
                        debugger;
                        // console.log(data.message);
                        this.toastr.success(data.message, '', {})
                        jQuery('#myModal').modal('hide');
                        this.EditProfileForm.setErrors({ 'invalid': true });
                        this.myAccountDetails = "";
                        this.myAccountDetails = data;
                        this.Logindetails = JSON.parse(localStorage.getItem('saDashBoardData'));
                        const oldEmail = this.Logindetails.loginID;
                        const NewEmail = this.EditProfileForm.value.emailid;
                        // console.log(this.EditProfileForm)
                        this.EditProfileForm.setErrors(null);
                        if (oldEmail != NewEmail) {
                            debugger;
                            this.router.navigateByUrl('/login');
                        } else {
                            debugger;
                            return null
                        }
                        // this.EditProfileForm.markAsPristine();


                    } else {
                        this.toastr.error(data.message, '', {});
                        jQuery('#myModal').modal('hide');
                        // formDirective.resetForm();
                        //  this.ChangePasswordForm.reset()
                    }
                }
            )
        }

    }

    updateUserDetails() {
        debugger;
        if(this.EditProfileForm.dirty || this.stationCode.dirty){
            jQuery('#myModal').modal({ backdrop: 'static', keyboard: false });
        }else{
            this.toastr.error('No values changed')
        }

        // console.log(UpdatedDetails)
    }

    viewpro(val) {
        debugger;
        if (val === 'view') {
            this.colorChangeone = 'load-delay';
            this.colorChangetwo = 'load-delayone ';
            this.colorChangethree = 'load-delayone ';
            this.viewreport = true;
            this.editeport = false;
            this.changerpass = false;
            //  this.EditProfileForm.setErrors({'invalid': true})
             this.stationCode.setValue({'invalid':true})

        } else if (val === 'edit') {
            debugger;
            this.colorChangeone = 'load-delayone';
            this.colorChangetwo = 'load-delay ';
            this.colorChangethree = 'load-delayone ';
            this.viewreport = false;
            this.editeport = true;
            this.changerpass = false;
            console.log(this.myAccountDetails);

            this.EditProfileForm.setValue({
                firstname: this.myAccountDetails.firstName,
                lastname: this.myAccountDetails.lastName,
                emailid: this.myAccountDetails.loginId,
                stationcode: this.myAccountDetails.stationCode,
                contactnumber: this.myAccountDetails.contactNumber,
                address: this.myAccountDetails.address,
            });
            this.stationCode.setValue(this.myAccountDetails.stationCode);
             this.EditProfileForm.markAsPristine();
             this.stationCode.markAsPristine();
        } else if (val === 'change') {
            this.colorChangeone = 'load-delayone';
            this.colorChangetwo = 'load-delayone ';
            this.colorChangethree = 'load-delay';
            this.viewreport = false;
            this.editeport = false;
            this.changerpass = true;
            this.ChangePasswordForm.reset();
        }
    }


    //------ChangePasword

    passwordChange(ChangePasswordForm: any, formDirective: FormGroupDirective) {
        debugger;
        const obj: {} = {
            userId: this.Logindetails.userId,
            oldPassword: this.ChangePasswordForm.value.OldPassword,
            newPassword: this.ChangePasswordForm.value.NewPassword
        }
        this.spinner.show();
        this.ChangePasswordForm.invalid
        this.MyAccountService.passwordChange(obj).subscribe(
            data => {
                this.spinner.hide();
                console.log(data)
                if (data.responseCode === 1) {
                    this.toastr.success('Password Updated Successfully', '', {})
                    this.router.navigateByUrl('/login');
                    formDirective.resetForm();
                    this.ChangePasswordForm.reset()

                } else {
                    this.toastr.error(data.message, '', {});
                    this.ChangePasswordForm.valid
                    // formDirective.resetForm();
                    //  this.ChangePasswordForm.reset()
                }

            },
            error => {
                this.toastr.error('unable Updated Password', '', {});
                // alert('unable Updated Password ')
            }

        )
    }

    resetConfirmPassword() {
        debugger;
        // this.ChangePasswordForm.get('ConfirmPassword').
        this.ChangePasswordForm.controls['ConfirmPassword'].reset()

    }




    filterStations(stateobj: string) {
        debugger;
        // if(stateobj == ""){
        //     return this.stationsListObj.stationDetails
        // }else{
        //     console.log(this.stationsListObj)
        // return this.stationsListObj.stationDetails.filter(state =>
        //     state.portCode.toLowerCase().indexOf(stateobj.toLowerCase()) === 0);
        // }
        // this.stationsListObj = JSON.parse(localStorage.getItem('stationsList'));
           console.log(this.stationsListObj)
        return this.stationsListObj.stationDetails.filter(state =>
            state.portCode.toLowerCase().indexOf(stateobj.toLowerCase()) === 0);

    }

    onSelect(state) {
        debugger;
        console.log(this.EditProfileForm.value)
        console.log(this.stationCode.value)
        console.log(state)
        this.EditProfileForm.controls['stationcode'].patchValue(state.portCode);
        this.EditProfileForm.controls['address'].patchValue(state.address);
        console.log(this.EditProfileForm.value)
        console.log(this.stationCode.value)
    }

    updatedVal(e) {
        debugger;
        if (e && e.length === 0) {
            this.showAutocomplete = true;
        } else {
            this.showAutocomplete = false;
        }
    }


}



